import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageLayout from "../../PageLayout";
import AllProjectList from "./AllProjectList";
import { getAllProject } from "../../../store/actions/ProjectActions";
import UnAuthorizedComponent from "../../UnAuthorized/UnAuthorizedComponent";
import { isRoleMatch } from "../../../utils";
import LoadingOverlay from "react-loading-overlay-ts";

function AllProjects() {
  const User = useSelector((state) => state?.UserReducer);
  const userInformation = User?.userInformation;
  const dispatch = useDispatch();
  const user_group = useSelector((state) => state?.UserDetailsReducer?.userDetails?.UserGroup);
  // 3109 security matrix
  const [checkReadWriteAccess, setUserAccess] = useState(false);
  const [loader, setLoader] = useState(true);
  const {
    userDetails: { AccessGroup },
  } = useSelector((state) => state?.UserDetailsReducer);

  useEffect(() => {
    //load the api only when user has access as per 3109 security matrix
    if (user_group?.length && userInformation?.userid && checkReadWriteAccess) {
      const payLoad = {
        ArtworkAgilityPage: {
          UserGroup: user_group,
          PM: userInformation?.userid,
        },
      };
      dispatch(getAllProject(payLoad));
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [dispatch, user_group?.length, userInformation?.userid, checkReadWriteAccess]);
  // 3109 security matrix
  useEffect(() => {
    const isUserAccess = isRoleMatch(AccessGroup, [
      "CapacityManager",
      "ProjectManager",
      "TaskOwner",
    ]);
    setUserAccess(isUserAccess);
  }, [AccessGroup]);
  return (
    <>
      <PageLayout>
        <div className="content-layout" id="tableDiv">
          <div className="tabular-view">
            {
              /* - lets wait for to Load all the data */
              loader ? (
                <LoadingOverlay active={loader} spinner text="" />
              ) : /* 3109- Verify whether the user has access to this page*/
              checkReadWriteAccess ? (
                <AllProjectList header="All Projects" />
              ) : (
                AccessGroup && AccessGroup.length && <UnAuthorizedComponent nowrapper={true} />
              )
            }
          </div>
        </div>
      </PageLayout>
    </>
  );
}

export default AllProjects;
