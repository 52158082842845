import { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector, useDispatch } from "react-redux";
import { Column } from "primereact/column";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";

import DataTableGrid from "../../common/DataTableGrid/DataTableGrid";
import "./CapacityManagementPlaningHeader.scss";
import { getPlanningTaskLists, getPlanningProjectLists } from "../../../apis/acpApi";
import filter from "../../../assets/images/filter.svg";
import { ProjectService } from "../../../service/PegaService";
import TaskbodyTemplate from "./TaskbodyTemplate";
import { AcpService } from "../../../service/ACPService";
import AcpCloseDialog from "../common/AcpCloseDialog";
import { SaveChangesConfirmation } from "../constant";
import ACPFilter from "../common/ACPFilter";
import { multiFilterChange } from "../../../utils";

import {
  setAcpPlanningTaskDraftData,
  setAcpPlanningProjectDraftData,
  acpPlanningProjectData,
  acpPlanningData as setStoreAcpTask,
} from "../../../store/actions/AcpPlanningActions";
import { findDraftProjectData, findDraftTaskData } from "../../../service/ACPplanningService";
import { AcpCommonService } from "../../../service/AcpCommonService";

const TaskAndProjectListNavigation = ({
  setIsProjectData,
  setIsProjectListTab,
  setIsTaskData,
  saveChangesOption,
  setSaveChangesOption,
  loadTaskTable,
  loadProjectTable,
  setLoadProjectTable,
  setPlanningData,
  isSearch,
  acpFilters,
  task,
  supplierValue,
  reset,
  setReset,
  setActiveTable,
  filters,
  setFilters,
  selectedTask,
  setSelectedTask,
  selectedProject,
  setSelectedProject,
  PM,
  UserGroup,
  setFormValid,
  saveFlag,
  setSaveFlag,
  isTaskData,
  isProjectData,
  showDraftDialog,
  setShowDraftDialog,
  tabName,
  clearAllFilter,
  setClearAllFilter,
  taskData,
  setTaskData,
  setApplyFilters,
  changingTab,
  setChangingTab,
  setTabName,
  setLoadTaskTable,
  checkReadWriteAccess,
  resetKey,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const op = useRef(null);
  const toast = useRef(null);
  const colAllSelectedP = useRef([]);
  const selectedColumnListP = useRef([]);
  const selectedFieldsRefP = useRef([]);
  const filterArrayP = useRef([]);
  const [activeSection, setActiveSection] = useState("TaskList");
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const { acpPlanningData, acpPlanningProjectListData, taskDraftChanges, projectDraftChanges } =
    useSelector((state) => state?.AcpPlanningReducer);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [projectListColumnName, setProjectListColumnNames] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [InitialProjectData, setInitialProjectData] = useState([]);
  const [InitialTaskData, setInitialTaskData] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const [selectedColumnName, setSelectedColumnName] = useState("SelectAll");
  const [sortData, setSortData] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [Toggle, setToggle] = useState(false);
  const [conditionMatched, setConditionMatched] = useState(false);

  const [payload, setPayload] = useState({});
  const query = AcpService.useQuery();
  const { endDateQueryParams, startDateQueryParams } = AcpService.decodedParams(query);

  useEffect(() => {
    if (startDateQueryParams && endDateQueryParams) {
      const payload2 = {
        ArtworkAgilityPage: {
          PM: PM,
          UserGroup: UserGroup,
          End_Date: endDateQueryParams,
          Start_Date: startDateQueryParams,
        },
      };
      setPayload(payload2);
    } else if (
      acpFilters?.startDate &&
      acpFilters?.endDate &&
      !startDateQueryParams &&
      !endDateQueryParams
    ) {
      const payload1 = {
        ArtworkAgilityPage: {
          PM: PM,
          UserGroup: UserGroup,
          End_Date: acpFilters?.endDate,
          Start_Date: acpFilters?.startDate,
        },
      };
      setPayload(payload1);
    }
  }, [startDateQueryParams, endDateQueryParams, acpFilters?.startDate, acpFilters?.endDate]);

  useEffect(() => {
    if (acpPlanningProjectListData.length > 0) {
      let datawithDraftChanges = findDraftProjectData(
        acpPlanningProjectListData,
        projectDraftChanges,
        isProjectData
      );
      setProjectData(datawithDraftChanges);
      setInitialProjectData(datawithDraftChanges)
    }
  }, [acpPlanningProjectListData, projectDraftChanges]);

  useEffect(() => {
    if (supplierValue) {
      setFilters([]);
    }
    if (task) {
      setFilters([]);
    }
  }, [supplierValue, task]);

  useEffect(() => {
    if (clearAllFilter) {
      setFilters({});
      setSortData([]);
      clearAppliedFilters();
      setSelectedFields({});
      setClearAllFilter(false);
    }
  }, [clearAllFilter]);

  const applyTaskFilter = (filteredData) => {
    if (task) {
      if (task !== "All Tasks") {
        if (task === "Upload CIC") {
          setTaskData(filteredData.filter((ele) => ele.TaskType?.toLowerCase().includes("cic")));
          setInitialTaskData(filteredData.filter((ele) => ele.TaskType?.toLowerCase().includes("cic")));
        } else if (task === "FA Assembly") {
          setTaskData(
            filteredData.filter((ele) => ele.TaskType?.toLowerCase().includes("assembly"))
          );
          setInitialTaskData(filteredData.filter((ele) => ele.TaskType?.toLowerCase().includes("assembly")));
        }
      } else {
        setInitialTaskData(filteredData);
        setTaskData(filteredData);
      }
    } else {
      // no task assigned yet
      setInitialTaskData(filteredData);
      setTaskData(filteredData);
    }
  };

  const updateTopAndBottomFilterLogic = (filteredData) => {
    if (selectedFieldsRefP.current?.length) {
      const filterData = AcpService.filterDataByfilters(
        filteredData,
        selectedFieldsRefP.current,
        setConditionMatched,
        tabName
      );
      setFilters(filterData);
      selectedColumnListP.current = filterData;
    }
  };

  useEffect(() => {
    updateTopAndBottomFilterLogic(taskData);
  }, [taskData]);

  const applyTaskAndSupplierFilter = () => {
    let filteredData;
    let datawithDraftChanges = findDraftTaskData(acpPlanningData, taskDraftChanges, isTaskData);
    if (supplierValue) {
      //if supplier exists
      if (supplierValue !== "Select Supplier") {
        if (supplierValue === "NA") {
          filteredData = datawithDraftChanges?.filter((ele) => ele.Supplier?.toLowerCase() === "");
          applyTaskFilter(filteredData);
        } else {
          filteredData = datawithDraftChanges?.filter(
            (ele) => ele.Supplier?.toLowerCase() === supplierValue?.toLowerCase()
          );
          applyTaskFilter(filteredData);
        }
      } else {
        // value of Select Supplier
        applyTaskFilter(datawithDraftChanges);
      }
    } else {
      // no supplier set yet
      applyTaskFilter(datawithDraftChanges);
    }
  };

  useEffect(() => {
    if (acpPlanningData.length > 0) {
      setSelectedColumnName("SelectAll");
      applyTaskAndSupplierFilter();
    }
  }, [task, supplierValue, acpPlanningData, taskDraftChanges]);

  useEffect(() => {
    if (acpFilters?.startDate && acpFilters?.endDate) {
      setStartDate(acpFilters?.startDate);
      setEndDate(acpFilters?.endDate);
      setFilters([]);
    }
  }, [acpFilters?.startDate, acpFilters?.endDate]);

  const handleClick = (buttonName) => {
    setFilters([]);
    if (saveChangesOption) {
      setShowCloseDialog(true);
    }
    if (buttonName === "TaskList" && !saveChangesOption) {
      setActiveSection("TaskList");
      setActiveTable("TaskList");
      setIsProjectListTab(false);
    } else if (buttonName === "ProjectList" && !saveChangesOption) {
      setActiveSection("ProjectList");
      setActiveTable("ProjectList");
      setIsProjectListTab(true);
    }
  };

  const onSort = (column, direction, filterData) => {
    const newArray = [];
    const tableData = activeSection === "TaskList" ? taskData : projectData;

    const sortedData = AcpService.groupBy(
      filterData?.length ? filterData : filters?.length ? filters : tableData,
      "Group"
    );
    for (const [key, value] of Object.entries(sortedData)) {
      const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(value, column, direction);
      newArray.push(sortedDataSet);
    }
    const updatedData = newArray.flat();
    setSortData([column, direction]);
    localStorage.setItem("sortedColumnPlanning", JSON.stringify(true));
    // due to 3077
    if (filters?.length || filterData?.length) {
      setFilters(updatedData);
      selectedColumnListP.current = updatedData;
    } else {
      if (activeSection === "TaskList") {
        setTaskData(updatedData);
      } else {
        let datawithDraftChanges = findDraftProjectData(
          updatedData,
          projectDraftChanges,
          isProjectData
        );
        setProjectData(datawithDraftChanges);
      }
    }
  };

  const onGlobalFilterChange = (e, colName) => {
    const activeData = activeSection === "ProjectList" ? projectData : taskData;
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      activeData,
      filterArrayP,
      selectedColumnListP,
      colAllSelectedP,
      selectedFieldsRefP
    );

    const _selectedFieldsRef = selectedFieldsRefP?.current.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentPlanning");
    }
    if (filterArrayP.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelectedP.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const filterData = AcpService.filterDataByfilters(
          activeData,
          _selectedFieldsRefWithoutEmptyArray,
          setConditionMatched,
          tabName
        );
        const updatedFilterArray = AcpService.updateStartDateFormat(filterData);
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(updatedFilterArray);
          selectedColumnListP.current = updatedFilterArray;
        }
      } else {
        selectedColumnListP.current = [];
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnListP.current = [];
      setSelectedFields({});
      setFilters([]);
    }
  };

  const clearColumnWiseFilter = () => {
    let _selectedColumnName = selectedColumnName;
    const activeData = activeSection === "ProjectList" ? projectData : taskData;
    const selectedFields2 = _.cloneDeep(selectedFields);
    const _selectedFields = AcpService.emptyProperty(selectedFields2, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRefP.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentPlanning");
    }
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortedColumnPlanning");
      setSortData([]);
      if (Object.keys(__selectedFields)?.length === 0) {
        setClearAllFilter(true);
      }
    }
    //3078
    let data = [];
    if (Object.keys(__selectedFields)?.length) {
      data = [...(activeData || [])];
      Object.keys(__selectedFields).forEach((el) => {
        if (__selectedFields[el]?.length) {
          data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
        }
      });
    }
    if (data?.length) {
      setFilters(data);
    } else {
      setFilters([]);
      selectedFieldsRefP.current = [];
      const finalTaskData = _.cloneDeep(InitialTaskData);
      const finalProjectData = _.cloneDeep(InitialProjectData);
      selectedColumnListP.current = "ProjectList" ? finalProjectData : finalTaskData;
      activeSection === "ProjectList" ? setProjectData(finalProjectData) : setTaskData(finalTaskData);
    }
    setConditionMatched(false);
  };

  const clearAppliedFilters = () => {
    setFilters([]);
    setSortData([]);
    setSelectedFields({});
    setClearAllFilter(true);
    setConditionMatched(false);
    selectedFieldsRefP.current = {};
    selectedColumnListP.current = [];
    localStorage.removeItem("sortedColumnPlanning");
    localStorage.removeItem("_selectedFieldsRefCurrentPlanning");
  };

  const fetchData = async (payload) => {
    const res = await getPlanningTaskLists(payload);
    setLoader(false);
    if (res.length) {
      setLoader(false);
      setSelectAllChecked(false);
      setSelectedTask([]);
      setSelectedProject([]);
      setIsTaskData([]);
      setPlanningData((prev) => ({
        ...prev,
        isTask: true,
        planningData: [...acpPlanningData],
      }));
      setSelectedProject([]);
    } else {
      setPlanningData((prev) => ({
        ...prev,
        isTask: true,
        planningData: [],
      }));
      setTaskData([]);
      setSelectedTask([]);
      setSelectedProject([]);
      setIsTaskData([]);
      setLoader(false);
    }
  };

  const fetchProjectListData = async (payload) => {
    const res = await getPlanningProjectLists(payload);
    setLoader(false);
    if (res) {
      setSelectAllChecked(false);
      setSelectedTask([]);
      setSelectedProject([]);
      setIsProjectData([]);
      let datawithDraftChanges = findDraftProjectData(
        res?.data?.ProjectListACP,
        projectDraftChanges,
        isProjectData
      );
      setProjectData(datawithDraftChanges);
      setInitialProjectData(datawithDraftChanges);
      setPlanningData((prev) => ({
        ...prev,
        isTask: false,
        planningData: [...acpPlanningProjectListData],
      }));
    } else {
      setPlanningData((prev) => ({
        ...prev,
        isTask: true,
        planningData: [],
      }));
      setSelectedTask([]);
      setSelectedProject([]);
      setIsProjectData([]);
    }
  };
  const projectNameOnClick = (e, options) => {
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const fetch = (payload) => {
    clearAppliedFilters();
    if (activeSection === "ProjectList") {
      setLoader(true);
      // fetchProjectListData();

      (async () => {
        const res = await getPlanningProjectLists(payload);
        if (res) {
          setLoader(false);
          setSelectAllChecked(false);
          setSelectedTask([]);
          setSelectedProject([]);
          setIsProjectData([]);
          let datawithDraftChanges = findDraftProjectData(
            res?.data?.ProjectListACP,
            projectDraftChanges,
            isProjectData
          );
          setProjectData(datawithDraftChanges);
          setInitialProjectData(datawithDraftChanges);
          setPlanningData((prev) => ({
            ...prev,
            isTask: false,
            planningData: [...acpPlanningProjectListData],
          }));
        } else {
          setPlanningData((prev) => ({
            ...prev,
            isTask: true,
            planningData: [],
          }));
          setSelectedTask([]);
          setSelectedProject([]);
          setIsProjectData([]);
          setLoader(false);
        }
      })();
    } else if (activeSection === "TaskList") {
      setLoader(true);
      // fetchData();

      (async () => {
        const res = await getPlanningTaskLists(payload);
        if (res.length) {
          setLoader(false);
          setSelectAllChecked(false);
          setSelectedTask([]);
          setSelectedProject([]);
          setIsTaskData([]);
          setPlanningData((prev) => ({
            ...prev,
            isTask: true,
            planningData: [...acpPlanningData],
          }));
          setSelectedProject([]);
        } else {
          setPlanningData((prev) => ({
            ...prev,
            isTask: true,
            planningData: [],
          }));
          setTaskData([]);
          setSelectedTask([]);
          setSelectedProject([]);
          setIsTaskData([]);
          setLoader(false);
        }
      })();
    }
  };

  useEffect(() => {
    setLoader(true);
    if (startDate && endDate) {
      const payload1 = {
        ArtworkAgilityPage: {
          PM: PM,
          UserGroup: UserGroup,
          End_Date: endDateQueryParams ? endDateQueryParams : endDate,

          Start_Date: startDateQueryParams ? startDateQueryParams : startDate,
        },
      };
      fetch(payload1);
    }
  }, [endDate, startDate, activeSection, endDateQueryParams, startDateQueryParams]);

  useEffect(() => {
    const columnNames = ProjectService.getAvailablePlanningColumnNames();
    const projectColsNames = ProjectService.getAvailablePlanningProjectColumnNames();
    const taskcols = AcpService.reorderTaskListColumns(columnNames).map((el) => {
      return {
        Sequence: "",
        freeze: false,
        field_Name: el,
        reorder: false,
        column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
      };
    });
    const projectcols = AcpService.reorderProjectListColumns(projectColsNames).map((el) => {
      return {
        Sequence: "",
        freeze: false,
        field_Name: el,
        reorder: false,
        column_Name: el,
        sortAtoZ: false,
        sortZtoA: false,
      };
    });
    setProjectListColumnNames(projectcols);
    setProjectColumnNames(taskcols);
  }, []);

  const handleProjectSelectAll = (e) => {
    if (e.target.checked) {
      if (filters?.length) {
        filters?.map((el) => (el.isSelected = true));
        setSelectedProject(filters);
      } else {
        projectData?.map((el) => (el.isSelected = true));
        setSelectedProject(projectData);
      }
      setSelectAllChecked(true);
    } else {
      if (filters?.length) {
        filters?.map((el) => (el.isSelected = false));
      } else {
        projectData?.map((el) => (el.isSelected = false));
      }
      setSelectAllChecked(false);
      setSelectedProject([]);
    }
  };

  const handleTaskSelectAll = (e) => {
    if (e.target.checked) {
      if (filters?.length) {
        filters?.map((el) => (el.isSelected = true));
        setSelectedTask(filters);
      } else {
        taskData?.map((el) => (el.isSelected = true));
        setSelectedTask(taskData);
      }
      setSelectAllChecked(true);
    } else {
      if (filters?.length) {
        filters?.map((el) => (el.isSelected = false));
      } else {
        taskData?.map((el) => (el.isSelected = false));
      }
      setSelectAllChecked(false);
      setSelectedTask([]);
    }
  };

  const handleSelectItem = (item, selectedItems, setSelectedItems, dataLength) => {
    if (selectedItems?.includes(item)) {
      item.isSelected = false;
      const newSelectedItems = selectedItems.filter((i) => i !== item);
      setSelectedItems(newSelectedItems);
      setSelectAllChecked(false);
    } else {
      item.isSelected = true;
      const selectedList = [...selectedItems, item];
      setSelectedItems(selectedList);
      setSelectAllChecked(selectedList.length === dataLength);
    }
  };

  const handleSelectTask = (item) => {
    handleSelectItem(
      item,
      selectedTask,
      setSelectedTask,
      filters?.length ? filters?.length : acpPlanningData?.length
    );
  };

  const handleSelectProject = (item) => {
    handleSelectItem(
      item,
      selectedProject,
      setSelectedProject,
      filters?.length ? filters?.length : acpPlanningProjectListData?.length
    );
  };

  const handleDateChange = (options, field, value) => {
    //check if selected date is a weekend
    setFormValid(true);
    if (!saveFlag) {
      setSaveFlag(true);
    }
    options.isSelected = true;
    const updatedOptions = AcpService.slackDataCalculation(options, field, value);
    const temp = structuredClone(updatedOptions);
    //updatedOptions.tempNewStartDate = "";
    //tempNewStartDate to get fromDate
    //NewStartDate to get toDate

    //update isTaskData and isProjectData correctly
    if (activeSection === "TaskList") {
      setIsTaskData((prev) => {
        if (!updatedOptions.tempNewStartDate) {
          updatedOptions.tempNewStartDate = temp.NewStartDate;
          // updatedOptions.NewStartDate = value;
        } else {
          if (!updatedOptions.fromDate) {
            updatedOptions.fromDate = temp.NewStartDate;
          } else {
            updatedOptions.tempNewStartDate = updatedOptions.fromDate;
            updatedOptions.fromDate = temp.NewStartDate;
          }
        }

        if (prev.length > 0) {
          //need to test for RTA tasks as AWMTaskID is "" for RTA and valid AWMTaskID value for AWM tasks
          if (updatedOptions.AWMTaskID === "") {
            //RTA task
            if (prev.findIndex((p) => p.POANumber === updatedOptions.POANumber) !== -1) {
              const updatedData = prev.map((p) =>
                p.POANumber === updatedOptions.POANumber ? { ...p, ...updatedOptions } : p
              );

              return updatedData;
            } else {
              return [...prev, updatedOptions];
            }
          } else {
            //AWM task
            if (prev.findIndex((p) => p.AWMTaskID === updatedOptions.AWMTaskID) !== -1) {
              const updatedData = prev.map((p) =>
                p.AWMTaskID === updatedOptions.AWMTaskID ? { ...p, ...updatedOptions } : p
              );

              return updatedData;
            } else {
              return [...prev, updatedOptions];
            }
          }
        } else {
          return [updatedOptions];
        }
      });
    } else {
      setIsProjectData((prev) => {
        if (!updatedOptions.tempNewStartDate) {
          updatedOptions.tempNewStartDate = temp.NewStartDate;
          // updatedOptions.NewStartDate = value;
        } else {
          if (!updatedOptions.fromDate) {
            updatedOptions.fromDate = temp.NewStartDate;
          } else {
            updatedOptions.tempNewStartDate = updatedOptions.fromDate;
            updatedOptions.fromDate = temp.NewStartDate;
          }
        }

        if (prev.length > 0) {
          if (prev.find((p) => p.AWMProjectID === updatedOptions.AWMProjectID)) {
            const updatedData = prev.map((p) =>
              p.AWMProjectID === updatedOptions.AWMProjectID ? { ...p, ...updatedOptions } : p
            );
            return updatedData;
          } else {
            return [...prev, updatedOptions];
          }
        } else {
          return [updatedOptions];
        }
      });
    }
  };

  const handleNavigationTab = () => {
    if (activeSection === "TaskList") {
      setActiveSection("ProjectList");
      setActiveTable("ProjectList");
      setIsProjectListTab(true);
      setShowCloseDialog(false);
      setSaveChangesOption(false);
      setReset(true);
    } else if (activeSection === "ProjectList") {
      setActiveSection("TaskList");
      setActiveTable("TaskList");
      setIsProjectListTab(false);
      setShowCloseDialog(false);
      setSaveChangesOption(false);
      setReset(true);
    }
  };

  const handleContinue = () => {
    if (changingTab) {
      setIsTaskData([]);
      setIsProjectData([]);
      setSaveFlag(false);
      setShowDraftDialog(false);
      setTabName(changingTab);
      navigate(`/capacityManagement/${changingTab}`);
      setChangingTab();
    } else {
      setIsTaskData([]);
      setIsProjectData([]);
      setSaveFlag(false);
      setApplyFilters(true);
      setShowDraftDialog(false);
    }
  };

  const showToastMesssage = (summary, severity) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      life: 3000,
    });
  };

  useEffect(() => {
    if (loadTaskTable && payload && Object.keys(payload)?.length) {
      setLoader(true);
      clearAppliedFilters();
      fetchData(payload);
      setReset(true);
      setLoadTaskTable(false);
    } else if (loadProjectTable && payload && Object.keys(payload)?.length) {
      setLoader(true);
      clearAppliedFilters();
      fetchProjectListData(payload);
      setReset(true);
      setLoadProjectTable(false);
    }
  }, [loadTaskTable, loadProjectTable, payload]);
  useEffect(() => {
    if (Toggle) {
      document.getElementsByClassName("tab-content booking-tabs")?.[0] &&
        document
          .getElementsByClassName("tab-content booking-tabs")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
      document.getElementsByClassName("p-datatable-wrapper")?.[0] &&
        document
          .getElementsByClassName("p-datatable-wrapper")?.[0]
          .addEventListener("scroll", () => {
            if (!AcpService.isUBN(op?.current) || op?.current != null) {
              op?.current?.hide();
            }
          });
    }
  }, [Toggle]);
  const columnHeader = (options, keyId, col) => {
    return (
      <>
        {options === "SelectAll" ? (
          <>
            <div className="select-all-checkbox align-items-center">
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={
                  activeSection === "ProjectList" ? handleProjectSelectAll : handleTaskSelectAll
                }
                className="p-checkbox-box p-highlight"
                disabled={!checkReadWriteAccess}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex-display" key={keyId}>
              <img
                src={filter}
                alt="Column Filter"
                key={keyId}
                onClick={(e) => { setToggle(!Toggle); projectNameOnClick(e, options) }}
                className={
                  sortData?.includes(options) || selectedFields[options]?.[0]
                    ? "columnFilterIcon filter-color-change"
                    : "columnFilterIcon"
                }
              />
              {
                <span
                  className={
                    sortData?.includes(options) || selectedFields[options]?.[0]
                      ? "filter-color-change"
                      : ""
                  }
                >
                  {options === "AWMProjectID" && "Project ID"}
                  {options === "ProjectName" && "Project Name"}
                  {options === "PM" && "Owner"}
                  {options === "TaskName" && "Task Name"}
                  {options === "ProjectState" && "Project State"}
                  {options === "EstimatedOfPOAs" && "#of POAs"}
                  {options === "EstimatedAWReadiness" && "Artwork Readiness Date"}
                  {options === "StartDate" && "Current Start Date"}
                  {options === "Slack" && "Slack"}
                  {options === "NewStartDate" &&
                    (activeSection === "TaskList" ? "New Start Date" : "New Readiness Date")}
                  {(options === "AWMRemainingBuffer" || options === "RemainingBuffer") && "Buffer"}
                  {options === "BufferToWork" && "B:W"}
                </span>
              }
            </div>
          </>
        )}
      </>
    );
  };

  // body template of columns
  const taskbodyTemplate = (options, rowData) => {
    return (
      <TaskbodyTemplate
        optionsVal={options}
        rowDataVal={rowData}
        handleSelect={activeSection === "ProjectList" ? handleSelectProject : handleSelectTask}
        selected={activeSection === "ProjectList" ? selectedProject : selectedTask}
        handleDateChange={handleDateChange}
        setSaveChangesOption={setSaveChangesOption}
        setLoadProjectTable={setLoadProjectTable}
        activeSection={activeSection}
        showToastMesssage={showToastMesssage}
        checkReadWriteAccess={checkReadWriteAccess}
      />
    );
  };

  const dynamicColumns = (columnNames) => {
    return [
      columnNames?.map((ele, i) => {
        return (
          <Column
            key={ele?.field_Name}
            field={ele?.field_Name}
            filterField={ele?.field_Name}
            filterMatchMode="contains"
            filter={ele?.field_Name === "SelectAll" ? false : true}
            filterPlaceholder={ele?.field_Name}
            header={columnHeader(ele?.field_Name, ele?.field_Name, ele)}
            body={taskbodyTemplate}
            columnKey={ele?.field_Name || i}
            frozen={ele?.freeze}
            className={ele?.freeze ? "fontBoldcolor" : "cursorMove"}
            showFilterMenu={false}
            style={{
              width: ele?.freeze
                ? ele?.field_Name === "SelectAll"
                  ? "50px"
                  : "200px"
                : ele?.field_Name === "ProjectName"
                  ? "200px"
                  : ele?.width,
              maxWidth: "200px",
              minWidth: "80px",
              textOverflow: "ellipsis",
            }}
          />
        );
      }),
    ];
  };

  useEffect(() => {
    if (reset) {
      if (activeSection === "TaskList") {
        setTaskData([]);
        const cleanedTaskData = acpPlanningData?.map((task) => {
          for (let item of isTaskData) {
            if (item.AWMTaskID === task.AWMTaskID) {
              task.Slack = item.tempSlack;
            }
          }
          const { NewStartDate, tempNewStartDate, isSelected, ...rest } = task;
          return rest;
        });
        setTaskData(cleanedTaskData);
        dispatch(setStoreAcpTask(cleanedTaskData));
        setIsTaskData([]);

        setSelectedTask([]);
      } else if (activeSection === "ProjectList") {
        setProjectData([]);
        setInitialProjectData([]);
        const cleanedProjectData = acpPlanningProjectListData?.map((task) => {
          const { NewStartDate, tempNewStartDate, isSelected, ...rest } = task;
          return rest;
        });
        setProjectData(cleanedProjectData);
        setInitialProjectData(cleanedProjectData);
        dispatch(acpPlanningProjectData(cleanedProjectData));
        setIsProjectData([]);
        setSelectedProject([]);
      }
      dispatch(setAcpPlanningTaskDraftData([]));
      dispatch(setAcpPlanningProjectDraftData([]));
      setFilters([]);
      setFormValid(false);
      setReset(false);
      setSaveChangesOption(false);
      setSelectAllChecked(false);
    }
  }, [reset]);

  useEffect(() => {
    if (activeSection === "TaskList") {
      setPlanningData((prevState) => ({ ...prevState, planningData: taskData }));
    } else {
      setPlanningData((prevState) => ({ ...prevState, planningData: projectData }));
    }
  }, [taskData, projectData]);

  const changeSelectedFieldsForDate = (data) => {
    return data?.map((item) => {
      if (item.StartDate) {
        item.StartDate = AcpService.checkIfValidDate(item.StartDate)
          ? item.StartDate
          : !AcpService.isUBN(item.StartDate)
            ? AcpService.convertToShortDateFormat(item.StartDate)
            : "";
      }
      item.NewStartDate = AcpService.checkIfValidDate(item.NewStartDate)
        ? item.NewStartDate
        : !AcpService.isUBN(item.NewStartDate)
          ? AcpService.convertToShortDateFormat(item.NewStartDate)
          : "";
      return item;
    });
  };

  return (
    <>
      <div className="navigationButtonWrapper">
        <button
          onClick={() => handleClick("TaskList")}
          className={`${activeSection === "TaskList" ? "activeSection" : ""} navigationButton`}
        >
          <p className="navigationText">TaskList</p>
        </button>
        <button
          onClick={() => handleClick("ProjectList")}
          className={`${activeSection === "ProjectList" ? "activeSection" : ""} navigationButton`}
        >
          <p className="navigationText">ProjectList</p>
        </button>
      </div>
      <div className="taskAndProjectDetails myProjectAnddAllProjectList data-table-checkbox-column">
        <ACPFilter
          op={op}
          onSort={onSort}
          filters={filters}
          pageName="planning"
          sortData={sortData}
          setFilters={setFilters}
          projectData={
            activeSection === "TaskList"
              ? changeSelectedFieldsForDate(taskData)
              : changeSelectedFieldsForDate(projectData)
          }
          selectedFields={selectedFields}
          selectedColumnName={selectedColumnName}
          onGlobalFilterChange={onGlobalFilterChange}
          setSelectedColumnName={setSelectedColumnName}
          clearColumnWiseFilter={clearColumnWiseFilter}
        />
        <LoadingOverlay active={loader} spinner text="">
          {<Toast ref={toast} position="top-center" />}
          {activeSection === "TaskList" && taskData?.length > 0 ? (
            <>
              {projectColumnName?.length > 0 &&
                <DataTableGrid
                  data={conditionMatched ? [] : filters?.length ? filters : taskData}
                  isSearch={isSearch}
                  resetKey={resetKey}
                // frozenValue={taskDraftChanges}
                >
                  {dynamicColumns(projectColumnName)}
                </DataTableGrid>
              }
            </>
          ) : (
            activeSection === "TaskList" && (
              <div className="columnHeader item-center">
                No Task Data Available for selected date range!
              </div>
            )
          )}
          {activeSection === "ProjectList" && projectData?.length > 0 ? (
            <DataTableGrid
              // data={filters?.length ? filters : projectData}
              data={conditionMatched ? [] : filters?.length ? filters : projectData}
              isSearch={isSearch}
              resetKey={resetKey}
            //frozenValue={projectDraftChanges}
            >
              {dynamicColumns(projectListColumnName)}
            </DataTableGrid>
          ) : (
            activeSection === "ProjectList" && (
              <div className="columnHeader item-center">
                No Project Data Available for selected date range!
              </div>
            )
          )}
          {showCloseDialog && (
            <AcpCloseDialog
              setShowCloseDialog={setShowCloseDialog}
              showCloseDialog={showCloseDialog}
              handleNavigationTab={handleNavigationTab}
              popupCloseMessageText={SaveChangesConfirmation}
            />
          )}
          {showDraftDialog && (
            <AcpCloseDialog
              setShowCloseDialog={setShowDraftDialog}
              showCloseDialog={showDraftDialog}
              handleNavigationTab={handleContinue}
              popupCloseMessageText={SaveChangesConfirmation}
            />
          )}
        </LoadingOverlay>
      </div>
    </>
  );
};

export default TaskAndProjectListNavigation;
