import Api from ".";
import { DEVURL } from "./envUrl";

export const getAllFilesDetails = async (projectId, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/getAllFileDetails/${projectId}`;
  const addResponse = await axiosInstance({
    url: apiURL,
    method: "GET",
  });
  return {
    response: addResponse?.data?.FileDetails,
    specialProjectFlag: addResponse?.data?.SpecialProject,
  };
};
