import { Form } from "react-bootstrap";
import { Tooltip } from "primereact/tooltip";
import { dsbpConstant } from "../../DSBP/constant";
import React, { useState, useEffect, useMemo } from "react";
import CICInfo from "../../../assets/images/CICInfo.svg";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { debounce, isEqual } from "lodash";
import { Dropdown } from "primereact/dropdown";
import "./index.scss";
import { useUnsavedChangesWarning } from "../NavigationPrompt";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";

function CustomInput({
  key,
  src,
  alt,
  min,
  type,
  icon,
  value,
  field,
  width,
  style,
  filter,
  minDate,
  rowData,
  options,
  optionLabel,
  id,
  disabled,
  onChange,
  onSelect,
  showIcon,
  sortData,
  controlId,
  className,
  fieldType,
  filterData,
  slackValue,
  columnName,
  innerStyle,
  dateFormat,
  useOnSelect,
  optionArray,
  suggestions,
  placeholder,
  disabledDays,
  innerDisabled,
  internalField,
  innerClassName,
  dropdownMIData,
  dropDownOptions,
  contentEditable,
  wrapperClassName,
  setDropDownMatch,
  mismatchedOwnerRows,
  dropdownInitiateIDData,
  dropdownLoading = false,
  resetCustomInputs,
  setResetCustomInputs,
  itemTemplate,
  maxSelectedLabels,
  panelClassName,
  setEditingInProgress
}) {
  const [InputValue, setInputValue] = useState("");
  const [InitialValue, setInitialValue] = useState("");
  const [InputChanged, setInputChanged] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const { markDirty } = useUnsavedChangesWarning();

  
  useEffect(()=>{
    if(field === 'Regional_Design_Template' || field === 'Ink_Qualification' || field === 'Color_Development_and_Print_Trial'){
      setCheckboxValue(value.filter((obj)=>obj.AWM_Design_Job_ID.includes("_None"))?.length>0);
    }
  }, [value])
  

  useEffect(()=>{
    if(resetCustomInputs) setInputValue(InitialValue)
    setResetCustomInputs && setResetCustomInputs(false)
  },[resetCustomInputs])
  useEffect(() => {
    if (fieldType === "AWM_AddedToProject" && options[fieldType] === "Reject") {
      let temp = {
        AWM_AddedToProject: options[fieldType],
        AWM_ReasonforRejection: options?.AWM_ReasonforRejection,
        AWM_RejectionNotes: options?.AWM_RejectionNotes
      }
      setInitialValue(temp)
    } else {
      setInitialValue(value);
    }
  }, [JSON.stringify(filterData), sortData]);

  // const debouncedOnChange = debounce((options, e, field)=>handleChange(options, e, field), 500);
  const debouncedOnChange = useMemo(
    () => debounce((options, e, field) => handleChange(options, e, field), 800),
    []
  );

  const handleChange = (options, e, field) => {
    if (fieldType === "AWM_Other_Reference") {
      const updatedText = e.target.value.replace(/[^0-9]/g, "");
        onChange(
          updatedText,
          fieldType,
          options.DSBP_PMP_PIMaterialID,
          options,
          false, //removeUpdated will be always false if updatedText.length <= 8 //AWM-3539
          InitialValue
        );
      setInputChanged(false)
    } else if (fieldType === "AWM_GroupPMP") {
      // const updatedText = e.target.value;
      // setInputValue(updatedText);
      // if (value === updatedText) {
      //   setInputChanged(false);
      // } else {
      //   setInputChanged(true);
      // }
      onChange(options, e, "AWM_GroupPMP", rowData?.rowIndex, value === InitialValue, InitialValue);
    }else if (type === "EditableNumber" || type === "Toggle") {
      onChange(e, fieldType, options, rowData, value === InitialValue, InitialValue);
    } else if (type === "dropdownWithSearchAndSelect") {
      const updatedText = e?.target?.value ?? null;
      onChange(options, e, fieldType, updatedText === InitialValue, InitialValue);
    } else if (type === "multiDropdown_RDT_IQ_CD") {
      const updatedText = e?.target?.value;
      onChange(updatedText, field, id, options, InitialValue);
    } else {
      setInputValue(e?.target?.value);
      onChange(options, e, field, rowData, value === InitialValue, InitialValue);
      if (value === e?.target?.value) {
        setInputChanged(false);
      } else {
        setInputChanged(true);
      }
    }
  };

  const handleSelect = (e, selectionType) => {
    const value = e?.target?.value ?? null;
    if (fieldType === "AWM_Supporting_PMP_Design" || fieldType === "AWM_Supporting_PMP_Layout") {
      onChange(
        value === ""
          ? ""
          : value === InitialValue
          ? value
          : `${value}-${e?.target?.selectedOptions[0]?.getAttribute(
            "data-dropdownMIData"
          )}-${e?.target?.selectedOptions[0]?.getAttribute("data-dropdownInitiateIDData")}`,
        fieldType,
        options.DSBP_PMP_PIMaterialID,
        options,
        value === InitialValue,
        InitialValue
      );
    } else if (
      fieldType === "Role" ||
      fieldType === "Owner" ||
      fieldType === "Assignee" ||
      fieldType === "AWM_AISE" ||
      fieldType === "AWM_Biocide" ||
      fieldType === "AWM_Sellable" ||
      fieldType === "AWM_AddedToProject" ||
      fieldType === "AWM_AssemblyMechanism"
    ) {
      if (
        selectionType === "AutoSelect" &&
        (fieldType === "Role" || fieldType === "Owner" || fieldType === "Assignee")
      ) {
        onSelect(options, e, fieldType, rowData?.rowIndex, value === InitialValue, InitialValue);
      } else {
        onChange(options, e, fieldType, rowData?.rowIndex, value === InitialValue, InitialValue);
      }
    } else {
      onChange(value, fieldType, options?.DSBP_PMP_PIMaterialID, options, value === InitialValue, InitialValue);
    }
  };

  return (
    <div className="wrapper-width">
      {type === "Select" ? (
        <>
          <Form.Group style={style} disabled={disabled} controlId={controlId} className={className}>
            <Form.Select
              value={value}
              style={innerStyle}
              onChange={handleSelect}
              disabled={innerDisabled}
              placeholder={placeholder}
              className={innerClassName}
            >
              <option className="hidden-option" value="" default selected>
                Select
              </option>
              {fieldType === "AWM_GA_Brief"
                ? optionArray?.map((data) =>
                  data.File_Name === "Add GA Brief" ? (
                    <option key={data.File_Name} value={data.File_Name} style={{ color: "blue" }}>
                      {data.File_Name}
                    </option>
                  ) : (
                    <option key={data.File_Name} value={data.File_Name}>
                      {data.File_Name}
                    </option>
                  )
                )
                : fieldType === "AWM_CIC_Needed"
                  ? optionArray?.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))
                  : fieldType === "AWM_Supporting_PMP_Design"
                    ? optionArray?.map((ele, index) => (
                      <option
                        value={ele}
                        key={`${ele}_${index}`}
                        data-dropdownMIData={dropdownMIData[index]}
                        data-dropdownInitiateIDData={dropdownInitiateIDData[index]}
                      >
                        {ele}
                      </option>
                    ))
                    : fieldType === "AWM_Supporting_PMP_Layout"
                      ? optionArray?.map((ele, index) => (
                        <option
                          value={ele}
                          key={`${ele}_${index}`}
                          data-dropdownMIData={dropdownMIData[index]}
                          data-dropdownInitiateIDData={dropdownInitiateIDData[index]}
                        >
                          {ele}
                        </option>
                      ))
                      : fieldType === "AWM_AddedToProject"
                        ? optionArray?.map((data) => (
                          <option key={data.code} value={data.name}>
                            {data.name}
                          </option>
                        ))
                        : fieldType === "AWM_AISE"
                          ? optionArray?.map((data) => (
                            <option key={data.AWM_AISE} value={data.AWM_AISE}>
                              {data.AWM_AISE}
                            </option>
                          ))
                          : fieldType === "AWM_AssemblyMechanism"
                            ? optionArray?.map((data) => (
                              <option key={data.code} value={data.AWM_AssemblyMechanism}>
                                {data.AWM_AssemblyMechanism || options[field]}
                              </option>
                            ))
                            : fieldType === "AWM_Sellable"
                              ? optionArray?.map((data) => (
                                <option key={data.code} value={data.name}>
                                  {data.name}
                                </option>
                              ))
                              : fieldType === "AWM_Biocide"
                                ? optionArray?.map((data) => (
                                  <option key={data.code} value={data.name}>
                                    {data.name}
                                  </option>
                                ))
                                : ""}
            </Form.Select>
            {fieldType === "AWM_AddedToProject"
              ? options["RTA_POANumber"] === "" &&
              options["AWM_CICNeeded"] === "Yes" && (
                <span>
                  <img src={CICInfo} className="cicinfoicon" alt="" />
                  <Tooltip
                    position="top"
                    target=".cicinfoicon"
                    className="tooltip-white-cic"
                    content={dsbpConstant.addToProjectDisabledMessage}
                  />
                </span>
              )
              : null}
          </Form.Group>
        </>
      ) : type === "SeacrhAndSelectAutoComplete" ? (
        <>
          <Form.Group controlId={controlId} className={className}>
            <div className={wrapperClassName}>
              <CommonAutocomplete
                width={width}
                value={value}
                disabled={disabled}
                field={internalField}
                columnName={columnName}
                onChange={handleSelect}
                suggestions={suggestions}
                placeholder={placeholder}
                className={innerClassName}
                setDropDownMatch={setDropDownMatch}
                onSelect={(e) => (useOnSelect ? handleSelect(e, "AutoSelect") : null)}
              />
              {mismatchedOwnerRows?.includes(rowData.rowIndex) && <div>No results found</div>}
            </div>
          </Form.Group>
        </>
      ) : type === "dropdownWithSearchAndSelect" ? (
        <>
          <Dropdown
            value={value}
            filter={filter}
            disabled={disabled}
            options={dropDownOptions}
            placeholder={dropdownLoading ? `Loading ${field}..` : placeholder}

            className={innerClassName}
            loading={dropdownLoading}
            onChange={(e) => handleChange(options, e, field)}
          />
        </>
      ) : type === "multiDropdown_RDT_IQ_CD" ? (
        <>
          <div className="none_rdt_iq_cd">
            <div>
              <Checkbox
                className={innerClassName==="border-red" && "checkbox-border-red"}
                onChange={(e) => {
                  handleChange(
                    options, 
                    {target:{
                      value:
                        e.checked ? 
                        [{
                          AWM_Design_Job_ID : `${field==="Regional_Design_Template"? "DT" : field==="Color_Development_and_Print_Trial"? "NPF" : field==="Ink_Qualification"&& "IQ"}_None`,
                          AWM_Design_Job_Name : "None",
                          Design_Job_Status: "None"
                        }] :
                        []
                    }}, 
                    field)
                  setCheckboxValue(e.checked);
                }}
                checked={checkboxValue}
                disabled={disabled}
              ></Checkbox>
            </div>
            <span
              className={
                disabled && "disabled-text label-header-name"
              }
            >
              None
            </span>
          </div>
          <MultiSelect
            className={innerClassName}
            value={value.filter((obj)=>!obj.AWM_Design_Job_ID.includes("_None"))}
            onChange={(e) => {
              const values_selected = e.target.value.filter((obj)=>{
                return !obj.AWM_Design_Job_ID.includes("_None")
              })
              handleChange(options, {target:{value:values_selected}}, field)
              setCheckboxValue(false)
            }}
            options={options}
            optionLabel={optionLabel}
            placeholder={placeholder}
            filter
            itemTemplate={itemTemplate}
            maxSelectedLabels={maxSelectedLabels}
            panelClassName={panelClassName}
            disabled={disabled}
          />
        </>
      ) : type === "Number" ? (
        <>
          <InputNumber
            min={min}
            disabled={disabled}
            inputId="integeronly"
            className="input-duration"
            value={options?.data?.[field]}
            onChange={(e) => {
              if (fieldType === "TaskDuration") {
                if (parseInt(options?.data?.[field]) !== parseInt(e?.target?.value)) {
                  onChange(options, e, field);
                }
              } else {
                onChange(options, e, field);
              }
            }}
            onValueChange={(e) => {
              if (fieldType === "TaskDuration") {
                if (parseInt(options?.data?.[field]) !== parseInt(e?.target?.value)) {
                  onChange(options, e, field);
                }
              } else {
                onChange(options, e, field);
              }
            }}
          />
        </>
      ) : type === "EditableNumber" ? (
        <>
          <input
            key={key}
            value={value}
            disabled={disabled}
            className={innerClassName}
            contentEditable={contentEditable}
            onChange={(e) => handleChange(options, e, field)}
          />
        </>
      ) : type === "Toggle" ? (
        <>
          <img
            src={src}
            alt={alt}
            style={style}
            disabled={disabled}
            className={innerClassName}
            onClick={(e) => !disabled && handleChange(options, e, field)}
          />
        </>
      ) : type === "Date" ? (
        <>
          <Calendar
            icon={icon}
            value={value}
            minDate={minDate}
            showIcon={showIcon}
            disabled={disabled}
            className={className}
            dateFormat={dateFormat}
            disabledDays={disabledDays}
            onChange={(e) => {
              if (field === "StartDate") {
                const sv = slackValue ? "slackValue" : "";
                const isEqualData = isEqual(InitialValue, e.target.value);
                onChange(options, field, e.target.value, sv, isEqualData);
                if(!isEqualData){
                  markDirty();
                }
              } else {
                onChange(options, e, field);
              }
            }}
          />
        </>
      ) : fieldType === "AWM_Other_Reference" ? (
        <>
          <Form.Group controlId={controlId} style={{ textAlign: "-webkit-center" }}>
            <Form.Control
              onChange={(e) => {
                const updatedText = e.target.value.replace(/[^0-9]/g, "");
                // Limit the input to 8 characters
                if (updatedText.length <= 8) {
                  setInputValue(updatedText);
                  if (value === updatedText) {
                    setInputChanged(false);
                  } else {
                    setInputChanged(true);
                  }
                  debouncedOnChange(options, e, field)
                }
              }}
              value={InputChanged ? InputValue : value}
              className={innerClassName}
              placeholder={placeholder}
              disabled={disabled}
              style={style}
              type={type}
            />
            {options["AWM_Other_Reference"]?.length < 8 &&
              options["AWM_Other_Reference"]?.length !== 0 && (
                <div style={{ fontSize: 10, color: "red" }}>Valid PMP# is 8 digits</div>
              )}
          </Form.Group>
        </>
      ) : fieldType === "AWM_GroupPMP" ? (
        <>
          <Form.Group controlId={controlId} style={{ textAlign: "-webkit-center" }}>
            <Form.Control
              onChange={(e) => {
                setEditingInProgress(true)
                const updatedText = e.target.value;
                setInputValue(updatedText);
                if (value === updatedText) {
                  setInputChanged(false);
                } else {
                  setInputChanged(true);
                }
                debouncedOnChange(options, e, field)
              }}
              value={InputChanged ? InputValue : value}
              className={innerClassName}
              placeholder={placeholder}
              disabled={disabled}
              style={style}
              type={type}
            />
          </Form.Group>
        </>
      ): (
        <>
          <Form.Group controlId={controlId} style={{ textAlign: "-webkit-center" }}>
            <Form.Control
              onChange={(e) => handleChange(options, e, field)}
              value={InputChanged ? InputValue : value}
              className={innerClassName}
              placeholder={placeholder}
              disabled={disabled}
              style={style}
              type={type}
            />
          </Form.Group>
        </>
      )}
    </div>
  );
}

export default CustomInput;
