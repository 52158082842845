import React, { useState, useRef, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { Calendar } from "primereact/calendar";
import "./../CapacityManagementPlaningHeader.scss";
import "../../index.scss";
import CalendarIcon from "../../../common/CalendarIcon/CalendarIcon";
import CustomCalender from "../CustomCalender/CustomCalender";
import newCalendarIcon from "../../../../assets/images/acpCalenderIcon.svg";
import circularRightArrow from "../../../../assets/images/arrow_circle_right.svg";
import { useSelector, useDispatch } from "react-redux";
import { setAcpPlanningFilters } from "../../../../store/actions/AcpPlanningActions";
import { getStartEndDateFromWeek } from "../Utils/WeekHelper";
import { AcpService } from "../../../../service/ACPService";
import moment from "moment";
import { uniq } from "lodash";
import { getPlanningTaskLists } from "../../../../apis/acpApi";
import { useNavigate, useLocation } from "react-router-dom";
import { AcpCommonService } from "../../../../service/AcpCommonService";
import { UnsavedChangesDialog, useUnsavedChangesWarning } from "../../../common/NavigationPrompt";

const CapacityManagementPlaningHeader = ({
  setAcpFilters,
  acpFilters,
  setTask,
  setSupplierValue,
  isProjectListTab,
  PM,
  UserGroup,
  activeTable,
  setShowDraftDialog,
  isTaskData,
  isProjectData,
  applyFilters,
  setApplyFilters,
  formValid,
  setFormValid,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [dailyStartDate, setDailyStartDate] = useState(new Date());
  const currDate = new Date();
  currDate.setDate(currDate.getDate() + 10);
  const [dailyEndDate, setDailyEndDate] = useState(currDate);
  const [weekStartRange, setWeekStartRange] = useState();
  const [weekEndRange, setWeekEndRange] = useState();
  const [openStartWeekRange, setOpenStartWeekRange] = useState(false);
  const [openEndWeekRange, setOpenEndWeekRange] = useState(false);
  const [monthValue, setMonthValue] = useState("");
  const [endWeekRangeValue, setEndWeekRangeValue] = useState([]);
  const [weekRange, setWeekRange] = useState([]);
  const getRangeTypeOptions = () => {
    let options = [{ name: "Daily" }, { name: "Weekly" }, { name: "Monthly" }];

    return isProjectListTab
      ? options.filter((ele) => ele.name !== "Daily" && ele.name !== "Monthly")
      : options;
  };
  const optionType = getRangeTypeOptions();
  const currentDate = new Date();

  const { acpPlanningFilters, taskDraftChanges, projectDraftChanges } = useSelector(
    (state) => state.AcpPlanningReducer
  );
  const [filters, setFilters] = useState({});
  const [filterSubmitButton, setFilterSubmitButton] = useState(true);
  const [calender, setCalender] = useState(false);
  const [calenderDaily, setCalenderDaily] = useState(false);
  const [calenderMonthly, setCalenderMonthly] = useState(false);
  const dropdownRef = useRef(null);

  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());

  const [endcurrYear, setEndCurrYear] = useState(new Date().getFullYear());
  const [endcurrMonth, setEndCurrMonth] = useState(new Date().getMonth() + 1);

  const [year, setYear] = useState(currentDate.getFullYear());

  const task = [{ name: "All Tasks" }, { name: "Upload CIC" }, { name: "FA Assembly" }];
  const [supplier, setSupplier] = useState([]);
  const [supplierDropdown, setSupplierDropDown] = useState([]);
  const wrapperWeekStartRef = useRef(null);
  const wrapperWeekEndRef = useRef(null);
  const [initiapPegaData, setInitiapPegaData] = useState([]);
  const [savedEndDateQueryParams, setSavedEndDateQueryParams] = useState("");
  const [savedStartDateQueryParams, setSavedStartDateQueryParams] = useState("");
  // initial state for Awm-2907
  const { markClean } = useUnsavedChangesWarning();
  const [actionType, setActionType] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [pendingAction, setPendingAction] = useState("");
  const unsavedChanges = JSON.parse(localStorage.getItem("unsavedChanges"));

  const query = AcpService.useQuery();
  const {
    endDateQueryParams,
    supplierQueryParams,
    dateTypeQueryParams,
    taskTypeQueryParams,
    startDateQueryParams,
  } = AcpService.decodedParams(query);

  useEffect(() => {
    const filteredTasks = initiapPegaData?.filter((data) => {
      if (filters?.selectedTask && filters?.selectedTask === "FA Assembly") {
        return data?.TaskType?.includes("ssembly");
      } else if (filters?.selectedTask && filters?.selectedTask.includes("CIC")) {
        return data?.TaskType?.includes("CIC");
      } else {
        return data;
      }
    });

    let SupplierValue = filteredTasks.map((el) => {
      let val = el.Supplier.length > 0 ? el.Supplier : "NA";
      return val;
    });
    let uniqueSuppliers = uniq(SupplierValue);
    if (
      !filteredTasks?.length &&
      supplierQueryParams &&
      filters?.selectedTask !== taskTypeQueryParams
    ) {
      setSupplier([supplierQueryParams]);
    } else if (filteredTasks?.length && uniqueSuppliers?.length) {
      setSupplier(uniqueSuppliers);
    } else {
      setSupplier([]);
    }
    // due to 3072
  }, [filters?.selectedTask, initiapPegaData]);

  const fetchSupplierDataSpecificToTaskData = async () => {
    const payload = {
      ArtworkAgilityPage: {
        PM: PM,
        UserGroup: UserGroup,
        End_Date: endDateQueryParams ? endDateQueryParams : acpFilters?.endDate,
        Start_Date: startDateQueryParams ? startDateQueryParams : acpFilters?.startDate,
      },
    };
    const res = await getPlanningTaskLists(payload);
    if (res) {
      setInitiapPegaData(res);
      let SupplierValue = res.map((el) => {
        let val = el.Supplier.length > 0 ? el.Supplier : "NA";
        return val;
      });
      let uniqueSuppliers = uniq(SupplierValue);
      if (supplierQueryParams && filters?.selectedTask !== taskTypeQueryParams) {
        setSupplier([supplierQueryParams]);
      } else {
        setSupplier(uniqueSuppliers);
      }
    }
  };

  useEffect(() => {
    if (
      acpFilters?.startDate &&
      acpFilters?.endDate &&
      UserGroup?.length &&
      PM &&
      activeTable === "TaskList"
    ) {
      fetchSupplierDataSpecificToTaskData();
    }
  }, [
    PM,
    acpFilters,
    acpFilters?.startDate,
    acpFilters?.endDate,
    UserGroup?.length,
    endDateQueryParams,
    startDateQueryParams,
  ]);

  useEffect(() => {
    if (
      acpPlanningFilters &&
      !endDateQueryParams &&
      !startDateQueryParams &&
      !savedEndDateQueryParams &&
      !savedStartDateQueryParams
    ) {
      setFilters(acpPlanningFilters);
    } else if (startDateQueryParams && endDateQueryParams) {
      let updatedValue = {};
      updatedValue = {
        dailyEndDate: new Date(AcpService.convertToIST(endDateQueryParams)),
        dailyStartDate: new Date(AcpService.convertToIST(startDateQueryParams)),
      };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
    }
  }, [acpPlanningFilters, startDateQueryParams, endDateQueryParams, activeTable]);

  useEffect(() => {
    if (isProjectListTab && !taskTypeQueryParams) {
      setTask("All Tasks");
      setSupplierValue("Select Supplier");
      let updatedValue = {};
      updatedValue = { selectedTask: "All Tasks", selectedSupplier: "Select Supplier" };
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
    } else if (taskTypeQueryParams) {
      setSupplierValue(supplierQueryParams);
      let updatedValue = {};
      setTask(taskTypeQueryParams === "Upload CIC Matrix" ? "Upload CIC" : taskTypeQueryParams);
      updatedValue = {
        endDate: endDateQueryParams,
        startDate: startDateQueryParams,
        selectedTask:
          taskTypeQueryParams === "Upload CIC Matrix" ? "Upload CIC" : taskTypeQueryParams,
        selectedOption: dateTypeQueryParams,
        selectedSupplier: supplierQueryParams,
        dailyEndDate: new Date(AcpService.convertToIST(endDateQueryParams)),
        dailyStartDate: new Date(AcpService.convertToIST(startDateQueryParams)),
      };
      setSavedEndDateQueryParams(endDateQueryParams);
      setSavedStartDateQueryParams(startDateQueryParams);
      setDailyEndDate(new Date(AcpService.convertToIST(endDateQueryParams)));
      setSelectedDate(new Date(AcpService.convertToIST(startDateQueryParams)));
      setFilters((prev) => {
        return { ...prev, ...updatedValue };
      });
    }
  }, [
    isProjectListTab,
    endDateQueryParams,
    taskTypeQueryParams,
    supplierQueryParams,
    dateTypeQueryParams,
    startDateQueryParams,
  ]);

  useEffect(() => {
    if (
      filters?.selectedOption === "Daily" &&
      (savedStartDateQueryParams?.length || savedEndDateQueryParams?.length)
    ) {
      navigate({
        pathname: location.pathname,
      });
    }
  }, [filters?.selectedOption]);

  useEffect(() => {
    const formattedData = supplier?.map((item) => {
      return { name: item };
    });
    const sortedData = AcpService.sortDataAlphaNumeric(formattedData, "name");
    setSupplierDropDown([{ name: "Select Supplier" }, ...sortedData]);
  }, [supplier, supplierQueryParams]);

  const currentDateValue = new Date();
  const currentYear = currentDateValue.getFullYear();
  const currentMonth = currentDateValue.getMonth();
  const startMonth = currentMonth + 1;
  const endMonth = currentMonth + 7;
  const getNextMonths = () => {
    const monthNames = [];
    for (let i = startMonth; i <= endMonth; i++) {
      const month = i % 12 === 0 ? 12 : i % 12;
      const year = currentYear + Math.floor((i - 1) / 12);
      monthNames.push(new Date(year, month - 1, 1));
    }
    return monthNames;
  };

  const nextMonths = getNextMonths();
  const nextMonthName = nextMonths[nextMonths.length - 1];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const prevNextHandler = (type) => {
    let newMonth, newYear;
    let currentYear = currYear;
    if (type === "prev") {
      newMonth = currMonth - 1;
      newYear = currYear;
      if (newMonth < 0) {
        newMonth = 11;
        newYear--;
      }
    } else {
      newMonth = currMonth + 1;
      newYear = currentYear;
      if (newMonth > 11) {
        newMonth = 0;
        newYear += 1;
        currentYear++;
      }
    }

    setDate(new Date(newYear, newMonth, new Date().getDate()));
    setCurrYear(newYear);
    setCurrMonth(newMonth);
  };

  const endPrevNextHandler = (type) => {
    let newMonth, newYear;

    if (type === "prev") {
      newMonth = endcurrMonth - 1;
      newYear = endcurrYear;
      if (newMonth < 0) {
        newMonth = 11;
        newYear--;
      }
    } else {
      newMonth = endcurrMonth + 1;
      newYear = endcurrYear;
      if (newMonth > 11) {
        newMonth = 0;
        newYear++;
      }
    }
    setDate(new Date(newYear, newMonth, new Date().getDate()));
    setEndCurrYear(newYear);
    setEndCurrMonth(newMonth);
  };

  function getNumberOfWeeks(year, month) {
    // Adjust month for Date constructor which expects month index from 0 (January) to 11 (December)
    const date = new Date(year, month, 0); // 0 means the last day of the previous month
    const daysInMonth = date.getDate(); // Get the total days in that month
    // Calculate the number of full weeks in the month
    const validWeeks = Math.ceil(daysInMonth / 7);
    return validWeeks;
  }

  function calculateWeekRange({ year, month }) {
    const weeks = [];
    // Start the range from the first day of the month
    const firstOfMonth = new Date(year, month, 1);
    const lastOfMonth = new Date(year, month + 1, 0); // Last day of the month

    // Determine the first Monday on or before the first of the month
    const firstDayOfWeek = firstOfMonth.getDay();
    let currentStart = new Date(firstOfMonth);
    // Adjust to the previous Monday if the first day isn't Monday
    if (firstDayOfWeek !== 1) {
      currentStart.setDate(currentStart.getDate() - ((firstDayOfWeek + 6) % 7));
    }

    // Loop to generate week ranges
    while (currentStart <= lastOfMonth) {
      // Calculate the end of the week (next Sunday)
      let currentEnd = new Date(currentStart);
      currentEnd.setDate(currentStart.getDate() + 6); // 6 days later
      // Create the string for the range
      const startDay = currentStart.getDate();
      const startMonth = currentStart.toLocaleString("default", { month: "short" });
      const startYear = currentStart.toLocaleString("default", { year: "numeric" });
      const endDay = currentEnd.getDate();
      const endMonth = currentEnd.toLocaleString("default", { month: "short" });
      const endYear = currentEnd.toLocaleString("default", { year: "numeric" });
      // Adjust month name if the currentEnd falls into next month
      if (currentEnd.getMonth() !== currentStart.getMonth()) {
        weeks.push(`${startMonth} ${startDay} ${startYear}-${endMonth} ${endDay} ${endYear}`);
      } else {
        weeks.push(`${startMonth} ${startDay} ${startYear}-${startMonth} ${endDay} ${endYear}`);
      }

      // Move to the next week (next Monday)
      currentStart.setDate(currentStart.getDate() + 7);
    }

    return weeks;
  }

  const numberOfWeeks = getNumberOfWeeks(year, currMonth);
  const weekRanges = [];
  const twoWeekRange = [];
  const newWeekRange = numberOfWeeks + 4;
  let startDate = new Date(year, currMonth - 1, 1);
  let dateValue = startDate;
  const endWeekRanges = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    let startOfWeek = new Date(dateValue);
    startOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 1);
    let endOfWeek = new Date(dateValue);
    endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);

    weekRanges.push(
      `${startOfWeek.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}-` +
        `${endOfWeek.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}`
    );

    twoWeekRange.push(
      `${startOfWeek.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })}-${endOfWeek.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })}`
    );

    dateValue.setDate(dateValue.getDate() + 7);
  }

  for (let i = 0; i < newWeekRange; i++) {
    if (i === 0) {
      let startOfWeek = new Date(dateValue);
      //set start of the week date(Monday)
      startOfWeek.setDate(
        dateValue.getDate() - dateValue.getDay() + (dateValue.getDay() === 0 ? -6 : 1)
      );
      //set end of the week date(Friday)
      let endOfWeek = new Date(dateValue);
      endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);
    }

    let startOfWeek = new Date(dateValue);
    startOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 1);
    let endOfWeek = new Date(dateValue);
    endOfWeek.setDate(dateValue.getDate() - dateValue.getDay() + 7);

    twoWeekRange.push(
      `${startOfWeek.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })}-${endOfWeek.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      })}`
    );

    dateValue = endOfWeek;
  }

  const toggle = () => setOpenStartWeekRange(!openStartWeekRange);
  const toggleWeekEndRange = () => setOpenEndWeekRange(!openEndWeekRange);

  function handleClickOutside(event) {
    if (wrapperWeekStartRef.current && !wrapperWeekStartRef.current.contains(event.target)) {
      toggle();
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }

  function handleClickOutsideWeekEnd(event) {
    if (wrapperWeekEndRef.current && !wrapperWeekEndRef.current.contains(event.target)) {
      toggleWeekEndRange();
      document.removeEventListener("mousedown", handleClickOutsideWeekEnd);
    }
  }

  useEffect(() => {
    if (openStartWeekRange) document.addEventListener("mousedown", handleClickOutside);
  }, [openStartWeekRange]);

  useEffect(() => {
    if (openEndWeekRange) document.addEventListener("mousedown", handleClickOutsideWeekEnd);
    if (filters?.endDate && !filters.endStartDate && filters?.weekEndRange) {
      const { startDate } = getStartEndDateFromWeek(filters?.weekEndRange);
      const newStartDateForEndDate = AcpService.getFormattedDate(startDate);
      setFilters((prev) => {
        return {
          ...prev,
          endStartDate: moment(newStartDateForEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
        };
      });
    }
    // if (filters?.endDate || filters?.endStartDate) {
    //   const endDate = AcpService.formatDateConvert(
    //     filters?.endStartDate ? filters?.endStartDate : filters?.endDate
    //   );
    //   setEndCurrMonth(endDate?.month);
    //   setEndCurrYear(endDate?.year);
    // }
  }, [openEndWeekRange, filters]);

  useEffect(() => {
    const values = calculateWeekRange({ year: endcurrYear, month: endcurrMonth });
    setEndWeekRangeValue(values);
  }, [endcurrMonth, endcurrYear]);

  useEffect(() => {
    const values = calculateWeekRange({ year: currYear, month: currMonth });
    setWeekRange(values);
  }, [currMonth, currYear]);

  useEffect(() => {
    setMonthValue(nextMonthName);
  }, []);

  useEffect(() => {
    if (applyFilters) {
      setFilterSubmitButton(true);
      dispatch(setAcpPlanningFilters(filters));
      setAcpFilters(filters);
      setApplyFilters(false);
    }
  }, [applyFilters]);

  const handleSelectedTaskClick = (e) => {
    if (formValid || unsavedChanges) {
      setShowDialog(true);
      setPendingAction(e?.target?.value);
      setActionType("Task");
    } else {
      handleSelectedTask(e);
    }
  };

  const handleSelectedTask = (e) => {
    let updatedValue = {};
    updatedValue = { selectedTask: pendingAction ? pendingAction : e.target.value };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });
    setTask(pendingAction ? pendingAction : e.target.value);
    // AcpCommonService.updateQueryParams("TaskType", e?.target?.value, location, navigate);
    //callback to taskandprojectlist
  };

  const handleSelectedSupplierClick = (e) => {
    if (formValid || unsavedChanges) {
      setShowDialog(true);
      setActionType("Supplier");
      setPendingAction(e?.target?.value);
    } else {
      handleSelectedSupplier(e);
    }
  };

  const handleSelectedSupplier = (e) => {
    let updatedValue = {};
    updatedValue = { selectedSupplier: pendingAction ? pendingAction : e.target.value };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });
    setSupplierValue(pendingAction ? pendingAction : e.target.value);
    // AcpCommonService.updateQueryParams("Supplier", e?.target?.value, location, navigate);
    // setTask("All Tasks")
    //callback to taskandprojectlist
  };

  const handleSelectRangeType = (event) => {
    setFilterSubmitButton(false);
    const initialFilters = AcpService.getInitialFilterState();

    let newRangeType = event.target.value;

    // AcpCommonService.updateQueryParams("DateType", event?.target?.value, location, navigate);
    let updatedValue = {};

    switch (newRangeType) {
      case "Daily":
        const newDailyStartDate = AcpService.getFormattedDate(dailyStartDate);
        const newDailyEndDate = AcpService.getFormattedDate(dailyEndDate);
        updatedValue = {
          selectedOption: newRangeType,
          //3249 cd navigation
          dailyStartDate: savedStartDateQueryParams?.length
            ? new Date(AcpService.convertToIST(savedStartDateQueryParams))
            : newDailyStartDate,
          dailyEndDate: savedEndDateQueryParams?.length
            ? new Date(AcpService.convertToIST(savedEndDateQueryParams))
            : newDailyEndDate,
          startDate: savedStartDateQueryParams?.length
            ? savedStartDateQueryParams
            : moment(newDailyStartDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
          endDate: savedEndDateQueryParams?.length
            ? savedEndDateQueryParams
            : moment(newDailyEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
        };
        break;

      case "Weekly":
        updatedValue = {
          selectedOption: newRangeType,
          weekStartRange: initialFilters.weekStartRange,
          weekEndRange: initialFilters.weekEndRange,
          startDate: initialFilters.startDate,
          endDate: initialFilters.endDate,
        };
        break;

      case "Monthly":
        let startDate = new Date(date);
        startDate.setDate(1);
        startDate.setMonth(date.getMonth());
        const newStartdate = AcpService.getFormattedDate(startDate);
        let convertedStartDate = moment(newStartdate).format("YYYYMMDDTHHmmss.SSS [GMT]");
        let endDate = new Date(monthValue);
        let lastDayofMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
        let convertedEndDate = moment(lastDayofMonth).format("YYYYMMDDTHHmmss.SSS [GMT]");
        updatedValue = {
          selectedOption: newRangeType,
          monthStart: date,
          monthEnd: monthValue,
          startDate: convertedStartDate,
          endDate: convertedEndDate,
        };
        break;
      default:
        break;
    }

    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });
  };

  const handleStartDate = (e) => {
    setSavedStartDateQueryParams("");
    let convertedStartDate = moment(e.target.value).format("YYYYMMDDTHHmmss.SSS [GMT]");
    let updatedValue = {};
    updatedValue = { dailyStartDate: e.target.value, startDate: convertedStartDate };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });

    setSelectedDate(e.target.value);
    //3249 cd navigation
    setDailyStartDate(e.target.value);
    setCalender(false);
    setFilterSubmitButton(false);
    // AcpCommonService.updateQueryParams(
    //   "StartDate",
    //   AcpService.changeDateFromISTtoUTC(e.target.value),
    //   location,
    //   navigate
    // );
  };

  const handleEndDate = (e) => {
    setSavedEndDateQueryParams("");
    let updatedValue = {};
    let convertedEndDate = moment(e.target.value).format("YYYYMMDDTHHmmss.SSS [GMT]");
    updatedValue = { dailyEndDate: e.target.value, endDate: convertedEndDate };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });

    const selectedDate = e.target.value;
    setDailyEndDate(selectedDate);
    setFilterSubmitButton(false);
    // AcpCommonService.updateQueryParams(
    //   "EndDate",
    //   AcpService.changeDateFromISTtoUTC(e.target.value),
    //   location,
    //   navigate
    // );
  };

  const handleMonthStart = (e) => {
    let updatedValue = {};
    let convertedStartDate = moment(e.target.value).format("YYYYMMDDTHHmmss.SSS [GMT]");
    updatedValue = { monthStart: e.target.value, startDate: convertedStartDate };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });
  };

  const handleMonthEnd = (e) => {
    let updatedValue = {};
    let endDate = new Date(e.target.value);
    const newEndDate = AcpService.getFormattedDate(endDate);
    let lastDayofMonth = new Date(newEndDate.getFullYear(), newEndDate.getMonth() + 1, 0);
    let convertedEndDate = moment(lastDayofMonth).format("YYYYMMDDTHHmmss.SSS [GMT]");
    updatedValue = { monthEnd: e.target.value, endDate: convertedEndDate };
    setFilters((prev) => {
      return { ...prev, ...updatedValue };
    });
  };

  // useEffect(() => {
  //   if (filters?.weekStartRange) {
  //     const { startDate } = getStartEndDateFromWeek(filters?.weekStartRange);
  //     const newStartDate = AcpService.getFormattedDate(startDate);
  //     AcpCommonService.updateQueryParams(
  //       "StartDate",
  //       moment(newStartDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
  //       location,
  //       navigate
  //     );
  //   }
  //   if (filters?.weekEndRange) {
  //     const { endDate } = getStartEndDateFromWeek(filters?.weekEndRange);
  //     const newEndDate = AcpService.getFormattedDate(endDate);
  //     AcpCommonService.updateQueryParams(
  //       "EndDate",
  //       moment(newEndDate).format("YYYYMMDDTHHmmss.SSS [GMT]"),
  //       location,
  //       navigate
  //     );
  //   }
  //   if (filters?.monthStart) {
  //     AcpCommonService.updateQueryParams(
  //       "StartDate",
  //       moment(filters?.monthStart).format("YYYYMMDDTHHmmss.SSS [GMT]"),
  //       location,
  //       navigate
  //     );
  //   }
  //   if (filters?.monthEnd) {
  //     AcpCommonService.updateQueryParams(
  //       "EndDate",
  //       moment(filters?.monthEnd).format("YYYYMMDDTHHmmss.SSS [GMT]"),
  //       location,
  //       navigate
  //     );
  //   }
  // }, [filters?.weekStartRange, filters?.weekEndRange, filters?.monthStart, filters?.monthEnd]);

  const handleApiCall = () => {
    if (isTaskData.length > 0 || isProjectData.length > 0) {
      //show Dialog
      if (
        isTaskData?.length > taskDraftChanges?.length ||
        isProjectData?.length > projectDraftChanges?.length
      ) {
        setShowDraftDialog(true);
      } else {
        setFilterSubmitButton(true);
        dispatch(setAcpPlanningFilters(filters));
        setAcpFilters(filters);
      }
    } else {
      setFilterSubmitButton(true);
      dispatch(setAcpPlanningFilters(filters));
      setAcpFilters(filters);
    }
  };

  const confirmNavigation = () => {
    if (actionType === "Task") {
      handleSelectedTask(pendingAction);
    } else if (actionType === "Supplier") {
      handleSelectedSupplier(pendingAction);
    }
    markClean();
    setShowDialog(false);
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  return (
    <div className="actions acp-actions">
      {filters && (
        <Row>
          <Form.Group>
            <div className="acp-planning-table-dropdown">
              <div className="card flex justify-content-center">
                <Form.Select
                  className="select-FA"
                  // placeholder="Select FA"
                  disabled={isProjectListTab}
                  value={filters?.selectedTask}
                  onChange={handleSelectedTaskClick}
                >
                  {task?.map((type, index) => (
                    <option key={index} value={type?.name}>
                      {type?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="card flex justify-content-center">
                <Form.Select
                  className="select-supplier"
                  placeholder="Select Supplier"
                  onChange={handleSelectedSupplierClick}
                  value={filters?.selectedSupplier}
                  disabled={isProjectListTab}
                >
                  {supplierDropdown?.map((bve, index) => (
                    <option key={index} value={bve?.name}>
                      {bve?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="card flex justify-content-center">
                <Form.Select
                  className="select-supplier"
                  value={filters?.selectedOption}
                  onChange={handleSelectRangeType}
                  placeholder={filters?.selectedOption}
                >
                  {optionType?.map((type, index) => (
                    <option key={index} value={type?.name}>
                      {type?.name}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {filters?.selectedOption === "Daily" && (
                <div
                  className={
                    calender
                      ? "selected acp-calendar select-calender"
                      : "acp-calendar select-calender "
                  }
                >
                  <Calendar
                    dateFormat="d-M-y"
                    showIcon={true}
                    value={selectedDate}
                    onChange={handleStartDate}
                    onClick={() => {
                      setCalender(!calender);
                    }}
                    className={calender ? "calendar-icon-white" : "calendar-icon"}
                    icon={<CalendarIcon />}
                  />
                </div>
              )}

              {filters?.selectedOption === "Daily" && (
                <div
                  className={
                    calenderDaily
                      ? "selected acp-calendar select-calender"
                      : "acp-calendar select-calender "
                  }
                >
                  <Calendar
                    dateFormat="d-M-y"
                    showIcon={true}
                    value={dailyEndDate}
                    onChange={handleEndDate}
                    className={calenderDaily ? "calendar-icon-white" : "calendar-icon"}
                    onClick={() => {
                      setCalenderDaily(!calenderDaily);
                    }}
                    icon={<CalendarIcon />}
                    minDate={selectedDate}
                  />
                </div>
              )}

              {filters?.selectedOption === "Weekly" && (
                <>
                  <div ref={wrapperWeekStartRef} className={"weekStartRange"}>
                    <div>
                      <input
                        value={filters?.weekStartRange}
                        onClick={(e) => {
                          // handleStartWeekRange(e);
                          setOpenStartWeekRange(!openStartWeekRange);
                        }}
                        // onChange={handleStartWeekRange}
                        className={
                          openStartWeekRange
                            ? "inputWeekStartRange inputWeekStartRange-selected pointer "
                            : "inputWeekStartRange pointer"
                        }
                      />
                    </div>
                    <div
                      className={
                        openStartWeekRange
                          ? " calendar-icon-white calenderWeeklyIcon"
                          : "calenderWeeklyIcon"
                      }
                    >
                      <img
                        onClick={(e) => {
                          // handleStartWeekRange(e);
                          setOpenStartWeekRange(!openStartWeekRange);
                        }}
                        src={newCalendarIcon}
                        alt="Calendar"
                      />
                    </div>
                    {openStartWeekRange && (
                      <div className="calenderStartRange" ref={dropdownRef}>
                        <CustomCalender
                          weekStartRange={weekStartRange}
                          setWeekStartRange={setWeekStartRange}
                          setWeekEndRange={setWeekEndRange}
                          identifier={"start"}
                          weekEndRange={weekEndRange}
                          months={months}
                          currMonth={currMonth}
                          currYear={currYear}
                          prevNextHandler={prevNextHandler}
                          weekRanges={weekRange}
                          setFilterSubmitButton={setFilterSubmitButton}
                          filters={filters}
                          setFilters={setFilters}
                          setOpenStartWeekRange={setOpenStartWeekRange}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              {filters?.selectedOption === "Weekly" && (
                <div ref={wrapperWeekEndRef} className="weekStartRange">
                  <div>
                    <input
                      value={filters?.weekEndRange}
                      onClick={(e) => {
                        // handleEndWeekRange(e);
                        setOpenEndWeekRange(!openEndWeekRange);
                      }}
                      // onChange={handleEndWeekRange}
                      className={
                        openEndWeekRange
                          ? "inputWeekStartRange inputWeekEndRange-selected pointer"
                          : "inputWeekStartRange pointer"
                      }
                    />
                  </div>

                  <div
                    className={
                      openEndWeekRange
                        ? " calendar-icon-white calenderWeeklyIcon"
                        : "calenderWeeklyIcon"
                    }
                  >
                    <img
                      onClick={(e) => {
                        // handleEndWeekRange(e);
                        setOpenEndWeekRange(!openEndWeekRange);
                      }}
                      src={newCalendarIcon}
                      alt="Calendar"
                    />
                  </div>

                  {openEndWeekRange && (
                    <div className="calenderStartRange pointer">
                      <CustomCalender
                        weekStartRange={weekStartRange}
                        setWeekStartRange={setWeekStartRange}
                        setWeekEndRange={setWeekEndRange}
                        identifier={"end"}
                        weekEndRange={weekEndRange}
                        months={months}
                        currMonth={endcurrMonth}
                        currYear={endcurrYear}
                        prevNextHandler={endPrevNextHandler}
                        weekRanges={endWeekRangeValue}
                        setFilterSubmitButton={setFilterSubmitButton}
                        filters={filters}
                        setFilters={setFilters}
                        setOpenEndWeekRange={setOpenEndWeekRange}
                      />
                    </div>
                  )}
                </div>
              )}

              {filters?.selectedOption === "Monthly" && (
                <div className="acp-calendar select-calender">
                  <Calendar
                    dateFormat="M"
                    showIcon={true}
                    value={date}
                    onChange={(e) => {
                      setFilterSubmitButton(false);
                      setDate(e.value);
                      handleMonthStart(e);
                    }}
                    view="month"
                    className="calendar-icon"
                    icon={<CalendarIcon />}
                  />
                </div>
              )}

              {filters?.selectedOption === "Monthly" && (
                <div
                  className={
                    calenderMonthly
                      ? "selected acp-calendar select-calender"
                      : "acp-calendar select-calender "
                  }
                >
                  <Calendar
                    dateFormat="M"
                    showIcon={true}
                    value={monthValue}
                    onChange={(e) => {
                      setFilterSubmitButton(false);
                      setMonthValue(e.value);
                      handleMonthEnd(e);
                    }}
                    view="month"
                    className="calendar-icon"
                    icon={<CalendarIcon />}
                  />
                </div>
              )}

              <div
                onClick={!filterSubmitButton ? handleApiCall : null}
                className={
                  !filterSubmitButton
                    ? "circularRightArrow pointer"
                    : "circularRightArrow circularRightArrowDisable pointer"
                }
                disabled={filterSubmitButton}
              >
                <img src={circularRightArrow} alt="circularRightArrow" />
              </div>
            </div>
          </Form.Group>
        </Row>
      )}
      {/* UnsavedChangesDialog popup*/}
      <UnsavedChangesDialog
        visible={showDialog}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </div>
  );
};

export default CapacityManagementPlaningHeader;
