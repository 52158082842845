import "./index.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UnsavedChangesDialog } from "../NavigationPrompt";

export const CustomBreadCrum = ({ items, lastLabel }) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState({});

  const handleClickBreadcrum = (item) => {
    if (lastLabel !== item?.label) {
      const unsavedChanges = JSON.parse(localStorage.getItem("unsavedChanges"));
      if (unsavedChanges && item?.url) {
        setShowDialog(true);
        setPendingNavigation(item);
        return;
      } else {
        if (item?.url) {
          navigate(item?.url);
        }
      }
    }
  };

  const cancelNavigation = () => {
    setShowDialog(false);
  };

  const confirmNavigation = () => {
    localStorage.setItem("unsavedChanges", false);
    setTimeout(() => {
      if (pendingNavigation?.url) {
        navigate(pendingNavigation?.url);
      }
      setPendingNavigation({});
      setShowDialog(false);
    }, 100);
  };

  return (
    <>
      <div className="breadcrum-wrapper" style={{ display: "flex" }}>
        {items &&
          items?.map((item) => (
            <>
              <div className="breadcrum-item">
                <span className="p-menuitem-text" onClick={() => handleClickBreadcrum(item)}>
                  {item?.label}
                </span>
                <span className="p-breadcrumb-chevron pi pi-chevron-right piChevronRightMargin breadcrum-seperator-icon"></span>
              </div>
            </>
          ))}
      </div>
      <UnsavedChangesDialog
        visible={showDialog}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </>
  );
};
