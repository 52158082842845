import Api from ".";
import { DEVURL, AZUREURL, SITURL, PRODURL } from "./envUrl";

export const getProjectPlan = async (
  projectId,
  payLoad,
  headers = {
    "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
  }
) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/projectplan/${projectId}`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "POST",
      data: payLoad,
    });
    // Check for successful response status, e.g., 200 OK
    if (response?.status === 200) {
      const projectPlanData = response?.data?.ArtworkAgilityProjects;
      const reworkHistoryData = response?.data?.ReasonForReworkList;
      const AccessType = response?.data?.AccessType;
      const specialProject = response?.data?.SpecialProject;

      // return projectPlanData;
      return {
        projectPlanData,
        reworkHistoryData,
        projectState: response?.data?.ProjectState,
        responseStatus: response?.status,
        AccessType,
        specialProject: specialProject,
      };
    }
  } catch (error) {
    if (error.message.includes("net::ERR_CONNECTION_TIMED_OUT")) {
      // Handle the server being down
      return { error: "The server is currently unavailable. Please try again later." };
    } else {
      console.error("Error fetching project plan:", error);
      throw error; // Rethrow the error for other types of errors
    }
  }
};

export const getRolesOwners = async (projectId, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/rolesAndOwnersList/${projectId}`;
  try {
    const response = await axiosInstance({
      url: apiURL,
      method: "GET",
    });
    // Check for successful response status, e.g., 200 OK
    if (response?.status === 200) {
      const rolesOwnersData = response?.data?.ArtworkAgilityProjects;
      // return rolesOwnersData;
      return { rolesOwnersData, responseStatus: response?.status };
    }
  } catch (error) {
    if (error.message.includes("net::ERR_CONNECTION_TIMED_OUT")) {
      // Handle the server being down
      return { error: "The server is currently unavailable. Please try again later." };
    } else {
      console.error("Error fetching role and owners :", error);
      throw error; // Rethrow the error for other types of errors
    }
  }
};

export const activateProjectPlan = async (formData, projectId, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  let apiURL = `${DEVURL}/activateProject/${projectId}`;
  const activateResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  return activateResponse;
};

export const createNewAzureFolder = async (projectName, projecId, BU, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init({ headers });
  const folderStructure = `/${projectName}-${projecId}/${BU}/`;
  console.log("folderStructure", folderStructure);
  let apiURL = `${AZUREURL}/createFolders?path=${folderStructure}`;
  const activateResponse = await axiosInstance({
    url: apiURL,
    method: "POST",
  });
  return activateResponse;
};

export const saveProjectPlanAction = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init(headers);
  let apiURL;
  apiURL = `${DEVURL}/saveproject/${id}`;

  const saveProjectPlanActionData = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  return saveProjectPlanActionData;
};

export const storeApproveFileDetails = async (formData, id, headers = {}) => {
  const api = new Api();
  const axiosInstance = await api.init(headers);
  let apiURL;
  apiURL = `${DEVURL}/storeApproveFileDetails/${id}`;

  const storedFileDetails = await axiosInstance({
    url: apiURL,
    method: "POST",
    data: formData,
  });
  return storedFileDetails;
};
