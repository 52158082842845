// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrum-wrapper .breadcrum-item {
  cursor: pointer;
}
.breadcrum-wrapper .breadcrum-item:nth-last-child(1) {
  margin-top: -3px;
}
.breadcrum-wrapper .breadcrum-item:nth-last-child(1) .p-menuitem-text {
  color: #003da5;
  font-size: 15px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.breadcrum-wrapper .breadcrum-item:nth-last-child(1) .breadcrum-seperator-icon {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CustomBreadcrum/index.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AACI;EACE,gBAAA;AACN;AAAM;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,0JAAA;AAER;AACM;EACE,aAAA;AACR","sourcesContent":[".breadcrum-wrapper {\n  .breadcrum-item {\n    cursor: pointer;\n    &:nth-last-child(1) {\n      margin-top: -3px;\n      .p-menuitem-text {\n        color: #003da5;\n        font-size: 15px;\n        font-weight: 600;\n        font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Helvetica, Arial,\n          sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n      }\n      .breadcrum-seperator-icon {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
