import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './index.scss';
import ManageDependencyTable from './manageDependencyTable';

function ManageDependency({
  showManageDependency,
  setShowManageDependency,
  ProjectID,
  getApiParentPage,
  Assignee,
  finalPermission,
  setPageEdited,
  setInternalPageEdited,
}) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const headerTemplate = () => {
    return (
      <div className="md-dialog-header">
        <span className="md-header">Manage Dependency</span>
        <span className="md-actions">
          <Button
            className="button-layout chart-view-btn"
            variant="primary"
            //   onClick={() => setShowGoJSChart(true)}
            style={{cursor:"default"}}
          >
            Chart View
          </Button>
        </span>
      </div>
    );
  };

  const handleCancel = () => {
    setShowManageDependency(false);
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        className="manage-dependency"
        header={headerTemplate}
        // header="Manage Dependency"
        visible={showManageDependency}
        onHide={() => setShowManageDependency(false)}
        maximizable
        style={{ width: '80vw' }}
      >
        <ManageDependencyTable
          handleCancel={handleCancel}
          ProjectID={ProjectID}
          // passing value to its child
          setInternalPageEdited={setInternalPageEdited}
          setPageEdited={setPageEdited}
          setShowManageDependency={setShowManageDependency}
          setShowErrorMessage={setShowErrorMessage}
          getApiParentPage={getApiParentPage}
          Assignee={Assignee}
          setPageEdited={setPageEdited}
          finalPermission={finalPermission}
        />
      </Dialog>

      <Dialog
        visible={showErrorMessage}
        className="cyclic-error-dialog"
        onHide={() => {
          setShowErrorMessage(false);
        }}
        header={<div className="p-dialog-md">Error</div>}
      >
        <ul>
          <li>
            This action cannot be performed as it will create a CYCLE and therefore a DEADLOCK in
            the project plan, kindly review your dependencies mapping and try again
          </li>
        </ul>
      </Dialog>
    </div>
  );
}

export default ManageDependency;
