import "./index.scss";
import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
// import PDFViewer from '../PDFViewer/PDFviewer';
import { DataTable } from "primereact/datatable";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";
import useAzureUploadFolderStructure from "../../../hooks/useAzureUploadFolderStructure";
import { useProofScopeURL } from "../../ProofScope/ViewFiles";
import { AZUREURL } from "../../../apis/envUrl";
import { changeDateFormat, replaceSpecialChars, sanitizeFileName } from "../../../utils";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import CustomFileViewer from "../CustomFileViewer";
import downloadIcon from "../../../assets/images/download.svg";
import downloadPdfIcon from "../../../assets/images/openPDF.svg";
import { useParams } from "react-router-dom";

const VersionHistory = ({
  onHide,
  fileList,
  //   versionIndex,
  showVersionList,
  subFolder,
  toShowFileName = true,
  toShowVersion = true,
  toShowOwner = true,
  toShowDate = true,
  toShowAction = true,
  toShowDownloadOption = true,
  toShowViewOption = true,
  companyName = "",
}) => {
  const versionHistoryColumns = [
    { header: "Version", field: "Version", toShow: toShowVersion },
    { header: "Filename", field: "Filename", toShow: toShowFileName },
    { header: "Owner", field: "Owner", toShow: toShowOwner },
    { header: "Date", field: "Date", toShow: toShowDate },
  ];

  const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
  const [isPFFileOpen, setIsPFFileOpen] = useState(false);
  const [fileViewerData, setFileViewerData] = useState({});
  const { loadProofscope, fileContent, setFileContent } = useProofScopeURL();
  const [fileDetails, setFileDetails] = useState({});
  const [maximized, setMaximized] = useState(false);
  const { TaskDetailsData } = useSelector((state) => state.TaskDetailsReducer);
  let { ProjectID } = useParams();
  if (!ProjectID) {
    ProjectID = TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID;
  }

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const regionName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;
  const Project = ProjectID?.toUpperCase();

  //Added groupName based on task details for UBD and UCIC
  const groupName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.GABriefList?.[0]?.GroupName
    ? TaskDetailsData?.ArtworkAgilityTasks?.[0]?.GABriefList?.[0]?.GroupName
    : TaskDetailsData?.ArtworkAgilityTasks[0]?.CICDetails?.[0]?.GABriefFiles?.[0]?.GroupName;

  //Passing groupName to useAzureUploadFolderStructure
  const folderStructure = useAzureUploadFolderStructure(subFolder, undefined, false, groupName);
  const onMaximizeChange = (e) => {
    setMaximized(e.maximized);
  };
  const handleHide = () => {
    onHide(false);
    setMaximized(false);
  };
  const handleViewFile = async (fileDetails) => {
    setIsFileViewerOpen(true);
    setFileDetails(fileDetails);

    const re = /(?:\.([^.]+))?$/;
    const fileType = re.exec(fileDetails.Filename)[1]?.toLowerCase();
    const fileName = fileDetails?.Filename;

    let filePath;
    // If proofscope file exists in taskdetails
    if (!isEmpty(fileDetails.ReadOnlyURL)) {
      setIsPFFileOpen(true);
      setFileContent(fileDetails.ReadOnlyURL);
    } else {
      const downloadFileUrl = `${AZUREURL}/downloadFileFromContainerPath?path=/${sanitizeFileName(
        `${folderStructure}/${fileDetails.Version}/`
      )}&file=${fileName}`;
      const filePathRes = await fetch(downloadFileUrl);
      filePath = filePathRes.ok ? downloadFileUrl : null;
    }

    setFileViewerData({
      filePath,
      fileType,
      fileName,
    });
  };

  const OpenAndDownloadIcon = ({ el, subFolder }) => {
    const folderStructure = useAzureUploadFolderStructure(subFolder, el.Version, true, groupName);
    const folderStructureWithoutVersion = useAzureUploadFolderStructure(
      subFolder,
      el?.Version,
      false,
      groupName
    );

    const downloadPdf = (event, el) => {
      event.preventDefault();
      const fileName = el?.Filename ? el?.Filename : el?.File_Name;
      if (el?.isCopyXMLFile) {
        downloadAzureContainer(fileName, `${companyName}/Outbound/RTA_CopyXML`);
        return;
      }

      if (el?.ReferenceDocumentType) {
        if (
          el.ReferenceDocumentType.includes("GABriefFiles") ||
          el.ReferenceDocumentType.includes("GABDetails") ||
          el.ReferenceDocumentType.includes("OtherReferenceDocuments") ||
          el.ReferenceDocumentType.includes("ORDDetails")
        ) {
          //Added groupName of GA brief
          let folderStructure = `${BU}/${regionName}/${Project}/GA Brief/${groupName}/${el.Version}`;

          downloadAzureContainer(fileName, folderStructure);
        } else {
          if (el.ReferenceDocumentType.includes("RDTDetails")) {
            let folderStructure = `${BU}/${regionName}/${Project}/RDT/${el.JobID}/${el.Version}`;
            downloadAzureContainer(fileName, folderStructure);
          } else {
            downloadAzureContainer(
              fileName,
              `${folderStructureWithoutVersion}/${el.ReferenceDocumentType}/${el.Version}`
            );
          }
        }
        return;
      }
      downloadAzureContainer(fileName, folderStructure);
    };

    return (
      <div className="file-version-icon-container">
        {toShowDownloadOption && (
          <img
            src={downloadIcon}
            alt="downloadicon"
            className="download_icon"
            onClick={(e) => downloadPdf(e, el)}
          />
        )}
        {toShowViewOption && (
          <img
            alt="downloadpdficon"
            src={downloadPdfIcon}
            className="view_pdf_icon"
            onClick={() => handleViewFile(el)}
          />
        )}
      </div>
    );
  };

  const onFileViewerHide = () => {
    setIsFileViewerOpen(false);
    setIsPFFileOpen(false);
  };

  return (
    <div className="file-version-history-container">
      <Dialog
        headerClassName="version-history-dialog-header"
        className="version-history-dialog"
        visible={showVersionList}
        header={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Name}
        onHide={() => handleHide()}
        maximizable
        maximized={maximized}
        onMaximize={onMaximizeChange}
      >
        <div>
          <DataTable value={fileList} className="version-history-table">
            {versionHistoryColumns.map(
              (el, index) =>
                el?.toShow && (
                  <Column
                    key={index}
                    header={el?.header}
                    field={el?.field}
                    headerClassName="version-list-header"
                    bodyClassName="version-list-data-row"
                  ></Column>
                )
            )}
            {toShowAction && (
              <Column
                header="Action"
                headerClassName="version-list-header"
                bodyClassName="version-list-data-row"
                body={(el) => {
                  return <OpenAndDownloadIcon el={el} subFolder={subFolder} />;
                }}
              ></Column>
            )}
          </DataTable>
        </div>
      </Dialog>

      <Dialog
        header={replaceSpecialChars(fileDetails.Filename)}
        visible={isPFFileOpen}
        maximizable
        style={{ width: loadProofscope ? "70vw" : "50vw" }}
        onHide={onFileViewerHide}
      >
        {loadProofscope && fileDetails?.ReadOnlyURL === "" ? (
          <h4>Please wait!!! Proofscope file loading....</h4>
        ) : (
          <iframe
            src={fileContent}
            title="File Content"
            style={{ width: "100%", height: "500px" }}
            onLoad={() => {
              // Content has loaded
              setIsPFFileOpen(true);
            }}
          />
        )}
      </Dialog>
      {!isPFFileOpen && !isEmpty(fileViewerData.filePath) && (
        <CustomFileViewer
          fileViewerData={fileViewerData}
          isFileViewerOpen={isFileViewerOpen}
          onFileViewerHide={onFileViewerHide}
        />
      )}
    </div>
  );
};
export default VersionHistory;
