import "./index.scss";
import React, { useState } from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import PDFViewer from "../PDFViewer/PDFviewer";
import { DataTable } from "primereact/datatable";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import downloadPdfIcon from "../../../assets/images/downloadPdfIcon.svg";
import { downloadAzureContainer } from "../../../apis/downloadAzureContainer";

const Versionhistory = ({
  onHide,
  RowData,
  fileList,
  headerName,
  JobListData,
  ViewModalPDF,
  ProjectDetalis,
  showVersionList,
}) => {
  const versionHistoryColumns = [
    { header: "Version", field: "Version" },
    { header: "Filename", field: "Filename" },
    { header: "Owner", field: "Owner" },
    { header: "Date", field: "Date" },
  ];
  const [path, setPath] = useState("");
  const [ViewPdf, setViewPdf] = useState(false);
  const uniqueFileList = fileList.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.Version === item.Version && t.Filename === item.Filename)
  );

  const downloadPdf = (event, el) => {
    event.preventDefault();
    const Version = el?.Version;
    const fileName = el?.Filename;
    const filteredChildren = JobListData.filter((el) => el.key === RowData.parentKey);
    const JobName = filteredChildren?.[0]?.children.filter((el) => el.key === RowData.keyCode)?.[0]
      ?.data?.Filename;
    const Subfolder = filteredChildren?.[0]?.data?.Filename;
    // API response filepath for checking
    const filePath = el?.Filepath; // No need to lowercase or trim, since it's used only in folder checking
    const targetFolders = ["TechSpecXML", "CICMappingXML", "TD", "TasKXML", "XFDF"];

    // Check if the file has an `.xfdf` extension
    // const isXfdfFile = filteredChildren?.[0]?.data?.Filename?.toLowerCase().endsWith(".xfdf");
    const isXfdfFile = fileName?.toLowerCase().endsWith(".xfdf");

    // Find the folder that exists in the file path
    const matchedFolder =
      targetFolders.find((folder) => {
        const folderPattern = new RegExp(`\\b${folder}\\b`, "i");
        return folderPattern.test(filePath);
      }) || "";

    // Set the folder to XFDF if the file has the XFDF extension
    const finalFolder = isXfdfFile ? "XFDF" : matchedFolder;

    // Dynamically append the folder path if condition matches
    const folderStructure = finalFolder
      ? `${ProjectDetalis?.BU}/${
          ProjectDetalis?.Region
        }/${ProjectDetalis?.ProjectID?.toUpperCase()}/${Subfolder}/${
          Subfolder === "CPPFA" ? Subfolder : JobName
        }/${finalFolder}/${Version}/`
      : `${ProjectDetalis?.BU}/${
          ProjectDetalis?.Region
        }/${ProjectDetalis?.ProjectID?.toUpperCase()}/${Subfolder}/${
          Subfolder === "CPPFA" ? Subfolder : JobName
        }/${Version}/`;

    // const folderStructure = `${ProjectDetalis?.BU}/${ProjectDetalis?.Region}/${ProjectDetalis?.ProjectID} ${ProjectDetalis?.ProjectName}/${Subfolder}/${JobName}/${Version}/`;
    downloadAzureContainer(fileName, folderStructure);
  };

  const viewPdf = (options, ViewPFURL) => {
    setViewPdf(true);
    setPath(ViewPFURL);
  };
  return (
    <div>
      <Dialog
        headerClassName="version-history-dialog-header"
        className="version-history-dialog"
        visible={showVersionList}
        style={{ width: "75vw" }}
        header={headerName}
        onHide={onHide}
        maximizable
      >
        <div>
          <DataTable value={uniqueFileList} scrollable scrollHeight="400px">
            {versionHistoryColumns?.map((el) => (
              <Column header={el?.header} field={el?.field} />
            ))}
            <Column
              header="Action"
              body={(el) => {
                return (
                  <div className="flex-display">
                    <img
                      src={downloadIcon}
                      alt="downloadicon"
                      className="download_icon"
                      onClick={(e) => downloadPdf(e, el)}
                    />
                    {ViewModalPDF && (
                      <img
                        alt="downloadpdficon"
                        src={downloadPdfIcon}
                        className="view_pdf_icon"
                        onClick={() => viewPdf(el, el?.ViewPFURL)}
                      />
                    )}
                  </div>
                );
              }}
            ></Column>
          </DataTable>
        </div>
      </Dialog>

      <Dialog
        headerClassName="version-history-dialog-header"
        className="version-history-dialog"
        onHide={() => setViewPdf(false)}
        style={{ width: "80vw" }}
        header={headerName}
        visible={ViewPdf}
        maximizable
      >
        <div>
          <PDFViewer path={path} width={"100%"} height={"600px"} />
        </div>
      </Dialog>
    </div>
  );
};
export default Versionhistory;
