import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import PageLayout from "../../PageLayout";
import AddNewDesign from "../DesignJobs/TaskHeader";
import DesignHeader from "../DesignJobs/DesignHeader";
import AddNewDesignContent from "../DesignJobs/AddNewDesignContent";
import FooterButtons from "../DesignJobs/FooterButtons";
import {
  saveDefineRegionalDesignTemplate,
  submitDefineRegionalDesignTemplate,
} from "../../../apis/defineRegionalDesignTemplate";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddNavigation, redirectToProjectPlanOrTaskPage } from "../../../utils";
import { toLower } from "lodash";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import {
  checkFormValidity,
  checkAllDesignJobsAreDefined,
  areAllSelectsTrue,
  createDIName,
  sortedDataAsPerJobID,
} from "../../../utils";
import "../DesignJobs/index.scss";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";

const roleName = "RDT";

function DDT() {
  const [data, setData] = useState(null);
  const [designIntent, setDesignIntent] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [submittedDI, setSubmittedDI] = useState([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [enableCheckBox, setEnableCheckBox] = useState(true);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location?.pathname;

  const dispatch = useDispatch();
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);

  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
  const Region = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region;
  const headerName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;

  let breadcrumb = AddNavigation(headerName);
  // if bu is baby care show tire field else not
  let checkBU = toLower(BU) === toLower("Home Care") ? true : false;

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    let allDesignJobs = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || [];
    if (TaskDetailsData) {
      let sortedData = sortedDataAsPerJobID(allDesignJobs, "Design_Job_ID");
      setDesignIntent(sortedData);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
    }
    if (allDesignJobs && allDesignJobs?.length) {
      const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(allDesignJobs);
      setEnableCheckBox(!checkAllJobsAreDefined);
      checkFormValidity(allDesignJobs, setEnableSubmit);
      const setCheboxValue = areAllSelectsTrue(allDesignJobs);
      setChecked(setCheboxValue);
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const handleDelete = (index) => {
    const updatedDesignIntent = [...designIntent]; // Create a copy of the original array
    const deletedItem = updatedDesignIntent[index];
    deletedItem.Action = "delete"; // Set the Action property to "delete" for the specified item
    deletedItem.Design_Job_Status = "delete"; // Update the deleted items state
    setDeletedItems([...deletedItems, deletedItem]);
    // Remove the deleted item from the designIntent array
    updatedDesignIntent.splice(index, 1);
    setDesignIntent(updatedDesignIntent);

    // Update the state with the modified array
    const checkAllJobsAreDefined = checkAllDesignJobsAreDefined(updatedDesignIntent);
    setEnableCheckBox(!checkAllJobsAreDefined);
    const deletedDesignIntent = designIntent.filter((item) => item.Action !== "delete");
    checked && setChecked(deletedDesignIntent?.length !== 0);
  };

  useEffect(() => {
    if (designIntent.length === 0) setEnableSubmit(true);
    else {
      checkFormValidity(designIntent, setEnableSubmit);
    }
  }, [designIntent]);

  const addNewEmptyDesign = () => {
    designIntent.push({
      Design_Job_ID: designIntent.length + 1,
      isNew: true,
      Agency_Reference: "",
      Cluster: "",
      Additional_Info: "",
      Select: false,
      Design_Job_Status: "",
      Tier: "",
    });
    const checkBoxUnselected = designIntent.find((item) => !item.Select);
    if (checkBoxUnselected) {
      setChecked(false);
    }
    setEnableCheckBox(true);
    setDesignIntent(designIntent);
    setUpdated(!updated);
    setChecked(false);
  };

  const addData = (fieldName, index, value) => {
    let Project_Name = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;
    let data = designIntent[index];
    data[fieldName] = value;
    let di_name = createDIName(
      data.Agency_Reference,
      data.Cluster,
      data.Additional_Info,
      Project_Name,
      Brand,
      Category,
      roleName,
      data.Tier
    );
    data["Design_Job_Name"] = di_name;
    submittedDI.push(data);
    checkFormValidity(designIntent, setEnableSubmit);
    setSubmittedDI(submittedDI);
    const setCheboxValue = areAllSelectsTrue(designIntent);
    setChecked(setCheboxValue);
  };

  const onSelectAll = (checked) => {
    designIntent.map((task) => {
      if (task?.Event !== "submit") {
        task.Select = checked;
      }
      return task;
    });
    checkFormValidity(designIntent, setEnableSubmit);
    setDesignIntent(designIntent);
    setUpdated(!updated);
  };

  const handleDeleteJob = async (Design_Job_ID) => {
    setLoader(true);
    let updatedData = {};
    let updatedDataList = [];
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    // Combine the designIntent and deletedItems arrays for submission
    const combinedData = [...designIntent, ...deletedItems];
    let submitOnlySelectedData = combinedData.filter(
      (task) => Design_Job_ID === task?.Design_Job_ID
    );
    if (submitOnlySelectedData?.length > 0) {
      updatedData = {};

      updatedData.DesignJobName = submitOnlySelectedData[0]?.Design_Job_Name;
      updatedData.DesignJobID = submitOnlySelectedData[0]?.Design_Job_ID;
      updatedData.AgencyReference = submitOnlySelectedData[0]?.Agency_Reference;
      updatedData.Cluster = submitOnlySelectedData[0]?.Cluster;
      updatedData.AdditionalInfo = submitOnlySelectedData[0]?.Additional_Info;
      updatedData.Tier = submitOnlySelectedData[0]?.Tier;
      updatedData.Select = submitOnlySelectedData[0]?.Select
        ? submitOnlySelectedData[0].Select
        : false;
      updatedData.Action = "delete";

      updatedDataList.push({
        instruction: "APPEND",
        target: "DesignTemplateList",
        content: updatedData,
      });
    }

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineRegionalDesignTemplate",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: updatedDataList,
    };
    await submitDefineRegionalDesignTemplate(formData, id, headers);
    dispatch(getTaskDetails(TaskID, ProjectID));
    setLoader(false);
  };

  const onSubmit = async () => {
    setLoader(true);
    let updatedData = {};
    let updatedDataList = [];
    const headers = {
      key: "If-Match",
      value: TaskDetailsData?.ArtworkAgilityPage?.Etag,
    };

    // Combine the designIntent and deletedItems arrays for submission
    const combinedData = [...designIntent, ...deletedItems];
    let submitOnlySelectedData = combinedData.filter(
      (task) =>
        task?.Select === true &&
        (task?.Design_Job_Status === "" ||
          task?.Design_Job_Status === "Awaiting" ||
          task?.isNew === true)
    );
    submitOnlySelectedData.map((task) => {
      updatedData = {};
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.DesignJobName = task.Design_Job_Name;
      updatedData.DesignJobID = task.Design_Job_ID;
      updatedData.AgencyReference = task.Agency_Reference;
      updatedData.Cluster = task.Cluster;
      updatedData.AdditionalInfo = task.Additional_Info;
      updatedData.Tier = task.Tier;
      updatedData.Select = task.Select ? task.Select : false;
      updatedData.Action = task.Action;

      updatedDataList.push({
        instruction: "APPEND",
        target: "DesignTemplateList",
        content: updatedData,
      });
    });

    let formData = {
      caseTypeID: "PG-AAS-Work-DefineRegionalDesignTemplate",
      content: {
        AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
        AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      },
      pageInstructions: updatedDataList,
    };
    await submitDefineRegionalDesignTemplate(formData, id, headers);
    setDeletedItems([]);
    setLoader(false);
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const onSaveAsDraft = async () => {
    setLoader(true);
    let updatedData = [];
    const combinedData = [...designIntent, ...deletedItems];
    combinedData.filter((task) => {
      if (task?.isNew) {
        task.Design_Job_ID = "";
      }
      if (task?.Action === "delete") {
        task.Action = "delete";
      } else if (task?.Action !== "delete" && task?.Design_Job_ID) {
        task.Action = "update";
      } else if (task?.Action !== "delete" && task?.isNew === true) task.Action = "add";

      updatedData.push({
        Design_Job_Name: task.Design_Job_Name,
        Design_Job_ID: task.Design_Job_ID,
        Agency_Reference: task.Agency_Reference,
        Cluster: task.Cluster,
        Tier: task.Tier,
        Additional_Info: task.Additional_Info,
        Select: task.Select ? task.Select : false,
        Action: task.Action,
      });
    });

    let formData = {
      AWM_Project_ID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWM_Task_ID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      Project_Name: Project_Name,
      BU: BU,
      Region: Region,
      DesignTemplateList: updatedData,
    };

    await saveDefineRegionalDesignTemplate(formData);
    dispatch(getTaskDetails(TaskID, ProjectID));
    setDeletedItems([]);
    setLoader(false);
  };

  let Brand = [];
  let Category = [];

  if (TaskDetailsData?.ArtworkAgilityPage) {
    Brand = TaskDetailsData.ArtworkAgilityPage.Artwork_Brand;
    Category = TaskDetailsData.ArtworkAgilityPage.Artwork_Category;
  }

  const loadOnClearButton = () => {
    dispatch(getTaskDetails(TaskID, ProjectID));
  };

  return (
    <PageLayout>
      <DesignHeader
        setAddNewDesign={addNewEmptyDesign}
        onSelectAll={onSelectAll}
        breadcrumb={breadcrumb}
        headerName={headerName}
        label={shortName}
        checked={checked}
        setChecked={setChecked}
        enableCheckBox={enableCheckBox}
        {...data}
      />
      <div className="task-details">
        {<AddNewDesign {...data} TaskDetailsData={TaskDetailsData} />}

        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent &&
            designIntent.length > 0 &&
            designIntent.map((item, index) => {
              if (item && item?.Action !== "delete") {
                return (
                  <AddNewDesignContent
                    key={index}
                    {...data}
                    item={item}
                    AWMProjectID={TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID}
                    AWMTaskID={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID}
                    Bu={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
                    Region={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Region}
                    handleDeleteJob={handleDeleteJob}
                    loadOnClearButton={loadOnClearButton}
                    onSaveAsDraft={onSaveAsDraft}
                    hideClearButton={true}
                    index={index}
                    addData={addData}
                    handleDelete={handleDelete}
                    roleName={roleName}
                    checkBU={checkBU}
                    Brand={Brand}
                    Category={Category}
                    taskName="RDT_Agency Ref_Brand_Category_Project name_Cluster _Tier_ Additional Info"
                    disabledDelete={designIntent.length === 1}
                  />
                );
              }
            })}
        </LoadingOverlay>
      </div>
      <FooterButtons
        handleCancel={handleCancel}
        onSaveAsDraft={onSaveAsDraft}
        onSubmit={onSubmit}
        bottomFixed={true}
        formValid={enableSubmit}
        hideSaveButton={true}
        {...data}
      />
    </PageLayout>
  );
}

export default DDT;
