//Authentication
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";

// drop down value
export const GET_DROPDOWN_VALUES_REQUEST = "GET_DROPDOWN_VALUES_REQUEST";
export const GET_DROPDOWN_VALUES_SUCCESS = "GET_DROPDOWN_VALUES_SUCCESS";

// ProjectPlan
export const UPDATE_PROJECT_PLAN = "UPDATE_PROJECT_PLAN";
export const UPDATE_PROJECT_PLAN_DESIGN = "UPDATE_PROJECT_PLAN_DESIGN";
export const UPDATE_PROJECT_STATE = "UPDATE_PROJECT_STATE";
export const UPDATE_REWORK_HISTORY = "UPDATE_REWORK_HISTORY";

// MyProjects
export const GET_PROJECT_DETAILS_REQUEST = "GET_PROJECT_DETAILS_REQUEST";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_ERROR = "GET_PROJECT_DETAILS_ERROR";
export const GET_OWNER_DETAILS_SUCCESS = "GET_OWNER_DETAILS_SUCCESS";
// AllProjects
export const GET_ALL_PROJECT_DETAILS_REQUEST = "GET_ALL_PROJECT_DETAILS_REQUEST";
export const GET_ALL_PROJECT_DETAILS_SUCCESS = "GET_ALL_PROJECT_DETAILS_SUCCESS";
export const GET_ALL_PROJECT_DETAILS_ERROR = "GET_ALL_PROJECT_DETAILS_ERROR";

// MyTasks
export const GET_MY_TASKS_SUCCESS = "GET_MY_TASKS_SUCCESS";
export const GET_MY_TASKS_ERROR = "GET_MY_TASKS_ERROR";

// AllTasks
export const GET_ALL_TASKS_SUCCESS = "GET_ALL_TASKS_SUCCESS";
export const GET_ALL_TASKS_ERROR = "GET_ALL_TASKS_ERROR";

// UpdateProject
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

// ProjectSetUp
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const SELECTED_PROJECT = "SELECTED_PROJECT";
export const UPDATE_MODE = "UPDATE_MODE";
export const SPECIAL_PROJECT = "SPECIAL_PROJECT";

// User
export const UPDATE_USER = "UPDATE_USER";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";

//DSBP dropdown
export const GET_ALL_DSBP_DATA = "GET_ALL_DSBP_DATA";
export const GET_ALL_DSBP_DATA_ERROR = "GET_ALL_DSBP_DATA_ERROR";

// ArtWorkTabValues
export const UPDATE_ARTWORK_TAB_VALUES = "UPDATE_ARTWORK_TAB_VALUES";

//DependencyMappingTabValues
export const UPDATE_DM_TAB_VALUES = "UPDATE_DM_TAB_VALUES";
export const UPDATE_DM_TAB_ATTRIBUTES = "UPDATE_DM_TAB_ATTRIBUTES";

//ACP Booking Table
export const ACP_BOOKING_DATA = "ACP_BOOKING_DATA";
export const ACP_DASHBOARD_PROJECTS_REQUIRING_PM = "ACP_DASHBOARD_PROJECTS_REQUIRING_PM";

export const UPDATE_RTA_TASKS_RESPONSE = "UPDATE_RTA_TASKS_RESPONSE";
export const ACP_BOOKING_CAPACITY_VALUES_DATA = "ACP_BOOKING_CAPACITY_VALUES_DATA";
export const EMPTY_ERROR_DATA = "EMPTY_ERROR_DATA";

//ACP Planning Table
export const ACP_PLANNING_TASK_DATA = "ACP_PLANNING_TASK_DATA";
export const ACP_PLANNING_UPDATE_RTA_TASKS_RESPONSE = "ACP_PLANNING_UPDATE_RTA_TASKS_RESPONSE";
export const ACP_PLANNING_PROJECT_CAPACITY_VALUES_DATA =
  "ACP_PLANNING_PROJECT_CAPACITY_VALUES_DATA";
export const ACP_PLANNING_TASK_CAPACITY_VALUES_DATA = "ACP_PLANNING_TASK_CAPACITY_VALUES_DATA";
export const ACP_PLANNING_EMPTY_ERROR_DATA = "EMPTY_ERROR_DATA";
export const ACP_PLANNING_PROJECT_DATA = "ACP_PLANNING_PROJECT_DATA";
export const ACP_PLANNING_SET_DEFAULT_FILTERS = "ACP_PLANNING_SET_DEFAULT_FILTERS";
export const ACP_PLANNING_SET_FILTERS = "ACP_PLANNING_SET_FILTERS";
export const ACP_PLANNING_SET_TASK_DRAFT = "ACP_PLANNING_SET_TASK_DRAFT";
export const ACP_PLANNING_SET_PROJECT_DRAFT = "ACP_PLANNING_SET_PROJECT_DRAFT";

// Artwork Status
export const GET_ARTWORK_STATUS_DETAILS_REQUEST = "GET_ARTWORK_STATUS_DETAILS_REQUEST";
export const GET_ARTWORK_STATUS_DETAILS_SUCCESS = "GET_ARTWORK_STATUS_DETAILS_SUCCESS";
export const GET_ARTWORK_STATUS_DETAILS_ERROR = "GET_ARTWORK_STATUS_DETAILS_ERROR";
//owner list Project Plan
export const GET_OWNER_LIST = "GET_OWNER_LIST";

//Manage Dependency
export const UPDATE_MANAGE_DEPENDENCY = "UPDATE_MANAGE_DEPENDENCY";

// get User details in first login
export const GET_USER_DETAILS = "GET_USER_DETAILS";

//Update user selected BU From CAPACITY MANAGEMENT
export const UPDATE_SELECTED_USER_BU = "UPDATE_SELECTED_USER_BU";

// FOR USERS_LIST
export const USERS_LIST = "USERS_LIST";

// for rework rejection Markups
export const REWORK_MARKUPS = "REWORK_MARKUPS";
export const REWORK_TABLE_DATA = "REWORK_TABLE_DATA";
export const USER_NOTIFICATION_DETAILS = "USER_NOTIFICATION_DETAILS";
export const REASON_REWORK = "REASON_REWORK";

//RESET Store
export const RESET_STORE = 'RESET_STORE';

//update BU & REGION
export const UPDATE_BU_REGION = 'UPDATE_BU_REGION'