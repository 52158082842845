import React, { useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import moment from "moment";
import PageLayout from "../../PageLayout";
import DesignHeader from "../DesignJobs/DesignHeader";
import FooterButtons from "../DesignJobs/FooterButtons";
import AddNewDesign from "../DesignJobs/TaskHeader";
import { getTaskDetails } from "../../../store/actions/taskDetailAction";
import { submitRejectionReasonsARDT } from "../../../apis/uploadSubmitAPIs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { redirectToProjectPlanOrTaskPage } from "../../../utils";
import UploadDesignIntentProofscope from "../DesignJobs/UploadDesignIntentProofscope";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import { showBackgroundProcess } from "../../../Utils/helpers";
import { SpecialProjectAction } from "../../../store/actions/ProjectSetupActions";
import { redirectUnallocatedLinksToArtworkStatus } from "../../../utils";

const breadcrumb = [
  { label: "My Tasks", url: "/tasks" },
  { label: "Approve Regional Design Template" },
];

const ARDT = () => {
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [date, setDate] = useState("");
  const [version, setVersion] = useState(0);
  const [designIntent, setDesignIntent] = useState(null);
  const [formattedValue, setformattedValue] = useState(0);
  const [mappedFiles, setMappedFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [viewFileName, setViewFileName] = useState("");
  const [azureFile, setAzureFile] = useState("");
  const [loader, setLoader] = useState(false);
  const [azureFileUploadMsg, setAzureFileUploadMsg] = useState(false);
  let { TaskID, page1, page2, pageType, ProjectID } = useParams();
  const projectSetup = useSelector((state) => state.ProjectSetupReducer);
  const selectedProjectDetails = projectSetup?.selectedProject;
  const { TaskDetailsData, loading } = useSelector((state) => state.TaskDetailsReducer);
  const [formValid, setFormValid] = useState(false);
  const [commentsExist, setCommentsExist] = useState(true);
  const [comments, setComments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = `${TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key}`;
  const roleName = "DI_";
  const location = useLocation();
  const currentUrl = location.pathname;
  // const checkReadWriteAccess = CheckReadOnlyAccess();
  const checkReadWriteAccess = true;
  const headerName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.TaskPlaceholderName;
  const shortName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Type;

  //to be removed once proofscope handles the approval--start
  // const triggerProofscopeApprovalApi = async () => {
  //   let headers = {};
  //   let formData = {
  //     AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
  //     AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks[0]?.Task_ID,
  //     TaskKey: TaskDetailsData?.ArtworkAgilityTasks[0]?.Task_Key,
  //     FlowAction: TaskDetailsData?.ArtworkAgilityTasks[0]?.FlowAction,
  //     FlowName: TaskDetailsData?.ArtworkAgilityTasks[0]?.FlowName,
  //     ApprovalStatus: "Rejected",
  //   };
  //   await submitProofScopeApproval(formData, id, headers);
  //   dispatch(getTaskDetails(TaskID, ProjectID));
  // };
  //to be removed once proofscope handles the approval--end

  useEffect(() => {
    //to be removed once proofscope handles the approval--start
    // if (data && Object.keys(data)?.length && data?.ApprovalStatus === "") {
    //   triggerProofscopeApprovalApi();
    // }
    //to be removed once proofscope handles the approval--end

    if (
      data &&
      Object.keys(data)?.length &&
      data.ApprovalStatus === "Rejected" &&
      data.Task_Status !== "Complete-Rejected" &&
      data.Task_Status !== "Cancelled" &&
      comments.length
    ) {
      setFormValid(true); //submit button enabled
    } else {
      setFormValid(false); //submit button disabled
    }
  }, [data, comments]);

  useEffect(() => {
    dispatch(getTaskDetails(TaskID, ProjectID));
    setAzureFileUploadMsg(false);
  }, [dispatch, TaskID, ProjectID]);

  useEffect(() => {
    if (TaskDetailsData) {
      setDesignIntent(TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails || []);
      setData(TaskDetailsData?.ArtworkAgilityTasks?.[0] || []);
      const data =
        TaskDetailsData?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.FileMetaDataList?.[0] ||
        [];

      const specialProjectFlag = TaskDetailsData?.SpecialProject;
      dispatch(SpecialProjectAction(specialProjectFlag));
      redirectUnallocatedLinksToArtworkStatus(currentUrl, specialProjectFlag, navigate);
      if (data) {
        data.Version !== "" && setVersion(data?.Version);
        data.Timestamp !== "" &&
          setDate(moment(data.Timestamp, "YYYYMMDD[T]HHmmss.SSS [GMT]").format("DD-MMMM-YYYY"));
      }
    }
  }, [TaskDetailsData]);

  const handleCancel = () => {
    redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
  };

  const BU = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU;
  const projectName = TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Project_Name;

  const onSubmit = async () => {
    setLoader(true);
    const markuplist = [];
    const taskIdSpecificTableData = tableData?.filter((obj) => {
      return obj.MarkupTaskID === TaskID;
    });
    taskIdSpecificTableData?.length &&
      taskIdSpecificTableData.forEach((obj) => {
        let temp = {};
        if ((obj.version === null || obj.version === "") && obj.content) {
          temp.MarkupID = obj.markupId;
          temp.Markup = obj.content;
          temp.VersionNumber = parseInt(version);
          markuplist.push(temp);
        }
      });
    const headers = {};
    const formData = {
      AWMProjectID: TaskDetailsData?.ArtworkAgilityPage?.AWM_Project_ID,
      AWMTaskID: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_ID,
      TaskKey: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.Task_Key,
      FlowAction: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.FlowAction,
      FlowName: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.FlowName,
      ApprovalStatus: TaskDetailsData?.ArtworkAgilityTasks?.[0]?.ApprovalStatus,
      MarkupList: markuplist,
    };
    await submitRejectionReasonsARDT(formData, id, headers);
    localStorage.setItem("Approval_Task_Submitted", "true");
    const getTaskDetailsResponse = await dispatch(getTaskDetails(TaskID, ProjectID));
    showBackgroundProcess(toast);
    setTimeout(() => {
      if (getTaskDetailsResponse?.ArtworkAgilityTasks?.[0]?.ReworkCounter !== null) {
        if (
          !getTaskDetailsResponse?.ArtworkAgilityTasks?.[0]?.Task_Status.includes(
            "Complete-Rejected"
          )
        ) {
          redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
        }
      } else {
        if (
          !getTaskDetailsResponse?.ArtworkAgilityTasks?.[0]?.DesignJobDetails?.[0]?.Design_Job_Status.includes(
            "Rejected"
          )
        ) {
          redirectToProjectPlanOrTaskPage(page1, page2, pageType, ProjectID, navigate);
        }
      }
    }, 4000);
    // setLoader(false);
  };

  return (
    <PageLayout>
      <Toast ref={toast} />
      <DesignHeader
        breadcrumb={breadcrumb}
        headerName={headerName}
        disabled={true}
        label={shortName}
        checkReadWriteAccess={checkReadWriteAccess}
        actionButtonsFlag={true}
        {...data}
      />
      <div className="task-details">
        {
          <AddNewDesign
            {...data}
            checkReadWriteAccess={checkReadWriteAccess}
            TaskDetailsData={TaskDetailsData}
            approve={true}
            // approvalStatus={TaskDetailsData.ApprovalStatus}
            approvalStatus={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.ApprovalStatus}
          />
        }
        <LoadingOverlay active={loading || loader || designIntent === null} spinner text="">
          {designIntent && (
            <UploadDesignIntentProofscope
              {...designIntent}
              designIntent={designIntent}
              setformattedValue={setformattedValue}
              setAzureFile={setAzureFile}
              setFileName={setFileName}
              setMappedFiles={setMappedFiles}
              item={data}
              roleName={roleName}
              ArtworkAgilityPage={TaskDetailsData?.ArtworkAgilityPage}
              version={version}
              date={date}
              checkReadWriteAccess={checkReadWriteAccess}
              fileName={fileName}
              buName={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.BU}
              taskFolder="RDT"
              TaskID={TaskID}
              projectName={projectName}
              ProjectID={ProjectID}
              viewFileName={viewFileName}
              setViewFileName={setViewFileName}
              approve={true}
              // approvalStatus={TaskDetailsData.ApprovalStatus}
              approvalStatus={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.ApprovalStatus}
              comments={comments}
              setComments={setComments}
              tableData={tableData}
              setTableData={setTableData}
              upload={false}
              setCommentsExist={setCommentsExist}
            />
          )}
        </LoadingOverlay>
        <div style={{ marginLeft: 30 }}>
          {azureFileUploadMsg && (
            <Message
              severity="error"
              text="Failed to upload file via azure storage please connect pulse VPN and retry"
            />
          )}
        </div>
      </div>

      <FooterButtons
        handleCancel={handleCancel}
        onSubmit={onSubmit}
        checkReadWriteAccess={checkReadWriteAccess}
        bottomFixed={true}
        approve={true}
        // approvalStatus={TaskDetailsData.ApprovalStatus}
        approvalStatus={TaskDetailsData?.ArtworkAgilityTasks?.[0]?.ApprovalStatus}
        formValid={!formValid}
        // saveAsDraftEnable={!commentsExist}
        {...data}
      />
    </PageLayout>
  );
};
export default ARDT;
